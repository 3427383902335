import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {Radio} from 'react-icheck';
import queryString from 'query-string';

import {ORDER_DETAIL, CUSTOMER_ORDER_RECEIVED, B2BSOCKET_URL, ORDER_PAYMENT_UPDATE, CUSTOMER_ORDER_CANCEL, AWS_IMG_PATH, ORDER_COMPLAIN_API, GET_PAYMENT_CHECKOUT_ID_API, CUSTOMER_CC_ADD_API, GET_PAYMENT_STATUS_API, ORDER_STAMP_UPLOAD} from '../Comman/Constant';
import MapWithADirectionsRenderer from './orderTracking';
import OrderDetailSkeleton from '../skeleton/OrderDetailSkeleton';
import ChatWithAdmin from '../Widget/chatWithAdmin';


class OrderDetail extends Component {

    constructor(props){
        super(props);

        const params = queryString.parse(this.props.location.search);

        this.state = {    
            order_data: [],     
            shop_data: [] ,
            cart_details : [],     
            delivery_details: [],    
            selectedCard:[],
            coupon_discount: 0,
            delivery_cost: 0,       
            delivery_discount: 0,
            subtotal:0,
            order_status: "",
            payment_status: "",
            item_collected: "",
            product_ds_or_not: "",
            cvc: '',
            expiry: '',
            focused: '',
            name_card_order: '',
            card_number: '',
            helpType: localStorage.getItem('helpType') ? localStorage.getItem('helpType') : "",
            add_card_toggle: false,
            customerCardList: [],
            paymentMethodList: [],
            address_list: [],
            activeCartData: {},
            selectedCartData: {
                cvc:"",
                expire_month:"",
                name_card_order:"",
                card_number:"",
                focused:"",
            },
            urlParam: params,
            isLoading: true,  
            order_received: "no",
            price_show: "no",
            stamp_image: "",
            charged_code: ''
        }

        this.handlePaymentChange = this.handlePaymentChange.bind(this);
        this.getCheckoutId = this.getCheckoutId.bind(this);
        this.add_card_toggle = this.add_card_toggle.bind(this);
        this.addCardSubmit = this.addCardSubmit.bind(this);     
        this.updatePaymentData = this.updatePaymentData.bind(this);     
        
    }

    componentDidMount(){

        const device_id = localStorage.getItem('device_id');
        this.b2bconn = new WebSocket(B2BSOCKET_URL);
        this.b2bconn.onmessage = this.onMessage.bind(this)
        this.setState({
            isCartLoading: true,                
        });

        if(this.state.urlParam.id){
            const requestParam = {
                language_id: 1,
                device_id: device_id,
                order_id: this.props.match.params.id,
                checkout_id: this.state.urlParam.id,
            }
            this.fetchPaymentStatus(requestParam);
        } else {
            const requestParam = {
                language_id: 1,
                device_id: device_id,
                order_id: this.props.match.params.id
            }
            this.fetchData(requestParam);
        }
       
    }

    fetchPaymentStatus = (requestParam) => {
        axios.get(GET_PAYMENT_STATUS_API, {
            params: requestParam 
        })
        .then((response) => {  
            if(response.data.success){

                const socketData = {        
                    order_id: this.props.match.params.id,
                    orderid: this.props.match.params.id,
                    status: 'paid',
                    requesttype:"orderstatus",            
                }
                this.b2bconn.send(JSON.stringify(socketData));

                confirmAlert({
                    title: '',
                    message: response.data.success,
                    buttons: [
                      {
                        label: 'Okay',                        
                      },
                     
                    ]
                  });
                
                this.props.history.push('/order_detail/'+this.props.match.params.id);
                this.fetchData(requestParam);
            } else {
                confirmAlert({
                    title: '',
                    message: response.data.error,
                    buttons: [
                      {
                        label: 'Okay',                        
                      },
                     
                    ]
                  });
                
                this.props.history.push('/order_detail/'+this.props.match.params.id);
                this.fetchData(requestParam);
            }
                    
        });
    }

    fetchData = (requestParam) => {
        var that = this;
        axios.post(ORDER_DETAIL, requestParam,            
        { 
            headers: {
            'Authorization': requestParam.device_id 
            }
        })
        .then((response) => {                 
            if(response.data.code == 201){
                 this.props.history.push('/order_list')
            } else {
                this.setState({
                    order_data: response.data.cart_details.order_details ? response.data.cart_details.order_details : [],
                    order_id: response.data.cart_details.order_details.id,
                    customer_cancel: response.data.cart_details.order_details.customer_cancel,
                    d_confirm_time: response.data.cart_details.order_details.d_confirm_time,
                    item_collected_time: response.data.cart_details.order_details.item_collected_time,
                    d_delivered_time: response.data.cart_details.order_details.d_delivered_time,
                    arrived_time: response.data.cart_details.order_details.arrived_time,
                    customer_id: response.data.cart_details.order_details.customer_id, 
                    order_received: response.data.cart_details.order_details.received_or_not,               
                    shop_data : response.data.cart_details.shop_details,
                    delivery_details : response.data.cart_details.delivery_details ? response.data.cart_details.delivery_details : [],
                    cart_details : response.data.cart_details.cart_products_new_one ? response.data.cart_details.cart_products_new_one : [], 
                    selectedCartData : response.data.selectedCartData ? response.data.selectedCartData : [],       
                    paymentMethodList: response.data.payment_list ? response.data.payment_list : [],
                    customerCardList:  response.data.card_list ? response.data.card_list : [],
                    addressList:  response.data.address_list ? response.data.address_list : [],
                    isCartLoading: false,
                    delivery_cost: response.data.delivery_cost,    
                    delivery_discount: response.data.delivery_discount, 
                    coupon_discount: response.data.coupon_discount, 
                    subtotal: response.data.subtotal,  
                    final_amount: response.data.final_amount,        
                    unique_id: response.data.unique_id,
                    order_status: response.data.cart_details.order_details.status,
                    payment_method_label: response.data.cart_details.order_details.product_payment_type, 
                    payment_code: response.data.cart_details.order_details.payment_code,           
                    payment_status: response.data.cart_details.order_details.payment_status,
                    product_ds_or_not: response.data.cart_details.order_details.product_ds_or_not,
                    item_collected: response.data.cart_details.order_details.item_collected,
                    item_available: response.data.cart_details.order_details.item_available,
                    add_card_toggle: false,
                    isLoading: false,  
                    name_card_order:"",
                    card_number:"",
                    expire_month:"",
                    cvc_add:"",
                    price_show:response.data.price_show,
                    stamp_image: response.data.cart_details.order_details.stamp_image,
                    activeCartData: response.data.activeCartData,                    
                    charged_code: response.data.charged_code,                    
                });        
            }            
        }).catch(function (error) {               
            if(error.response.status==401){
                that.props.history.push('/sign-in');
            }
        });;
    }

    handleInputFocus = ({ target }) => {
        this.setState({
          focused: target.name
        });
    };

    handleInputCvvChange = (e) => {

        const name = e.target.name;
    
        var value = e.target.value.replace(/[^0-9\.]/g, '');
        this.setState({ [name]: value });
    }

    handleInputAddCvvChange = (e) => {

        const name = e.target.name;
    
        var value = e.target.value.replace(/[^0-9\.]/g, '');
        this.setState({ [name]: value });
    }

    handleInputNameChange = (e) => {

        const value = e.target.value.replace(/[^A-Za-z \\s]/g, '');
        const name = e.target.name;

        this.setState({ [name]: value });
    }

    handleInputCardNumberChange = (e) => {

        const name = e.target.name;
        var value =  e.target.value.replace(/[^0-9\.]/g, '');
        var splitVlaue = value.split(" ").join("");
        
        if (splitVlaue.length > 0) {
			splitVlaue = splitVlaue.match(new RegExp('.{1,4}', 'g')).join(" ");
		}

        this.setState({ [name]: splitVlaue });
    }

    handleInputExpireChange = (e) => {

        const name = e.target.name;
       
        var value = e.target.value.replace(/[^0-9\.]/g, '');
		var splitVlaue = value.split("/").join("");

		if (splitVlaue.length > 0) {
			splitVlaue = splitVlaue.match(new RegExp('.{1,2}', 'g')).join("/");
		}

        this.setState({ [name]: splitVlaue });
    }

    handlePaymentChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;
        const payment_method_label = e.target.dataset.payment_method_name;

        if(value == 'cod'){
            const requestParam = {
                 order_id: this.props.match.params.id,
                 product_payment_type: value,
            }
            this.updatePaymentData(requestParam);
        }

        this.setState({ 
            [name]: value,
            payment_method_label: payment_method_label,
            add_card_toggle: false,  
            payment_code: value
         });

    }

    updatePaymentData = (requestParam) => {
         axios.post(ORDER_PAYMENT_UPDATE, requestParam);
    }

    selectCard = (e) => {
        const card_id = e.target.dataset.enctid;
        const card_number = e.target.dataset.cnumber;
        const card_name = e.target.dataset.cname;
        const exp_month = e.target.dataset.cexpire;
        var expireData = exp_month.split("/");

        e.preventDefault();

        this.setState({ 
            card_id: card_id, 
            add_card_toggle: false,  
            selectedCartData: {
                cvc:"",
                expire_month:expireData[0],
                card_name:card_name,
                expire_year:expireData[1],
                card_number:card_number,
                focused:"",
            }     
         });      
    }

    getCheckoutId = (event) => {      
        const device_id = localStorage.getItem('device_id');
        event.preventDefault();

        const requestParam = {
            language_id: 1,
            device_id: device_id,
            order_id: this.props.match.params.id,
            total_order_amount: this.state.final_amount,
            product_payment_type: this.state.payment_code,
            card_id: this.state.card_id
        }
        
        axios.get(GET_PAYMENT_CHECKOUT_ID_API, 
            {
                params: requestParam 
            }
        ).then((response) => {  
        
            document.getElementById("shopperResultUrl").value = window.location.href;
            document.getElementById("payForOrder").action = response.data.action;
            document.getElementById('payForOrder').submit();
                  
        });
    }

    add_card_toggle = () =>{
        this.setState({
            add_card_toggle: true
        })
    }

    cancel_add_card_toggle = () => {
        this.setState({
            add_card_toggle: false
        })
    }

    addCardSubmit(event) {
        var that = this;
        const device_id = localStorage.getItem('device_id');
        const cardData = {       
            language_id: 1,    
            exp_mm: this.state.expire_month,    
            exp_yy: this.state.expire_month,
            card_name: this.state.name_card_order,
            card_no: this.state.card_number,
            device_id: device_id
        }
       
        axios.post(CUSTOMER_CC_ADD_API, cardData,
        { 
            headers: {
            'Authorization': cardData.device_id 
            }
        })
        .then((response) => { 
        
            const requestParam = {
                language_id: 1,
                device_id: device_id,
                order_id: this.props.match.params.id
            }

            this.setState({
                customerCardList: response.data.card_list ? response.data.card_list : [],            
                add_card_toggle: false,
                isLoading: false,  
                name_card_order:"",
                card_number:"",
                expire_month:"",
                cvc_add:"",      
                card_id: response.card_id ? response.card_id  : 0 ,
                selectedCartData: {
                    cvc:"",
                    expire_month: cardData.exp_mm,
                    card_name: cardData.card_name,
                    expire_year: cardData.exp_yy,
                    card_number: cardData.card_no,
                    focused:"",
                }  
            });
            
            //this.fetchData(requestParam);
        }).catch(function (error) {            
            if(error.response.status==401){
                    that.props.history.push('/sign-in');
            }
        });;     
        event.preventDefault();
    }

    orderIssue = () => {
    
        confirmAlert({

            customUI: ({ onClose }) => {
            return (
                <div className='react-confirm-alert-body'>
                    <form encType="multipart/form-data" onSubmit={this.handleSubmit}>
                        <div className="row">                                 
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Your Comment</label>
                                    <input type="text" className="form-control" id="complain_note_by_customer" name="complain_note_by_customer" placeholder="Your Comment" onChange={this.formDataChange} required/>
                                    <span className="text-danger">{this.state.commentRequred}</span>
                                </div> 
                                <div className="form-group">
                                    <label>Pls add item damaged image</label>
                                    <input type="file" className="form-control" id="complain_image_by_customer" name="complain_image_by_customer" onChange={this.fileUploadChange} />
                                </div> 
                            </div>                            
                        </div>
                   
                    <div className="react-confirm-alert-button-group"> 
                        <input type="submit" className="cuatom_small_btn" value="Send" name="order_issue" />                                                              
                        <button onClick={onClose}>Cancel</button>              
                    </div>  
                     </form>                
                </div>
            );
            }
        });
    }

    driverIssue = () => {
        
        confirmAlert({

            customUI: ({ onClose }) => {
            return (
                <div className='react-confirm-alert-body'>
                    <form encType="multipart/form-data" onSubmit={this.handleSubmit}>
                        <div className="row">                        
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Your Comment</label>
                                    <input type="text" className="form-control" id="complain_note_by_customer" name="complain_note_by_customer" placeholder="Your Comment" onChange={this.formDataChange} required/>
                                </div> 
                                <div className="form-group">
                                    <label>Pls add item damaged image</label>
                                    <input type="file" className="form-control" id="complain_image_by_customer" name="complain_image_by_customer" onChange={this.fileUploadChange} />
                                </div> 
                            </div>
                        </div>
                    
                    <div className="react-confirm-alert-button-group"> 
                        <input type="submit" className="cuatom_small_btn" value="Send" name="driver_issue" />                                                                   
                        <button onClick={onClose}>Cancel</button>              
                    </div>  
                    </form>                
                </div>
            );
            }
        });
    }

    helpToggle = (event) => {
        const helpType = event.currentTarget.getAttribute('data-help');
        this.setState({
            helpType: helpType
        });
        localStorage.setItem('helpType',helpType);
        document.querySelector('#help_sidebar').scrollIntoView({behavior: 'smooth'});
    }

    formDataChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    fileUploadChange = (event) => {
        this.setState({[event.target.name]: event.target.files[0]});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const issue_type = event.target.name;
        const flag = issue_type == "order_issue" ? 1 : 2;
        const issueData = new FormData();

        const customer_mobile = localStorage.getItem('customer_mobile');
        const customer_firstname = localStorage.getItem('firstname_order');

        if(!this.state.complain_note_by_customer){
            confirmAlert({
                title: '',
                message:"Please add your comment.",
                buttons: [
                {
                    label: 'Okay',                        
                },
                
                ]
            });
            this.setState({
                commentRequred: "Please add your comment."
            });
            this.orderIssue();
            return true;
        }

        issueData.append('complain_image_by_customer',  this.state.complain_image_by_customer);
        issueData.append('order_id',  this.state.order_id);
        issueData.append('language_id',  1);
        issueData.append('complain_note_by_customer',  this.state.complain_note_by_customer);
        issueData.append('notification_count',  1);
        issueData.append('flag', flag);
        issueData.append('customer_name',  customer_firstname);
        issueData.append('customer_mobile',  customer_mobile);

        axios.post(ORDER_COMPLAIN_API, issueData).then((response) => {    
            
            this.state.complain_note_by_customer = "";
            this.state.complain_image_by_customer = ""
            confirmAlert({
                title: '',
                message:response.data.success,
                buttons: [
                {
                    label: 'Okay',                        
                },
                
                ]
            })      
        });

        event.preventDefault();
    }

    cancel_order_confirm = () => {
        confirmAlert({
            
                customUI: ({ onClose }) => {
                  return (
                    <div className='react-confirm-alert-body'>
                      <p className="text-danger cant_delivery_here">
                         Are you sure, You want to cancel order?.</p>
                        <div className="react-confirm-alert-button-group">
                        <button onClick={() => {
                                this.cancel_order();
                                onClose();
                            }} 
                        className="red_btn">YES</button>
                        <button onClick={onClose}>No</button>
                      </div>                  
                    </div>
                  );
                }
              });
    }

    order_received = () => {
        var that = this;
        this.setState({
            order_received: "yes",            
        });  
        axios.post(CUSTOMER_ORDER_RECEIVED, {                
            language_id: 1,    
            logged_user_id: this.state.customer_id,
            order_id: this.state.order_id,        
        },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id')
            }
        }).then((response) => {          
            const socketData = {        
                order_id: this.state.order_id,
                order_received: 'yes',
                requesttype:"orderstatus",            
            }
            this.b2bconn.send(JSON.stringify(socketData))                      
        }).catch(function (error) {               
            if(error.response.status==401){
                    that.props.history.push('/sign-in');
            }
        });
    }

    cancel_order = () => {
        var that = this;
        axios.post(CUSTOMER_ORDER_CANCEL, {                
            language_id: 1,    
            logged_user_id: this.state.customer_id,
            order_id: this.state.order_id,        
        },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id')
            }
        }).then((response) => {          
            const socketData = {        
                order_id: this.state.order_id,
                customer_cancel: 'yes',
                requesttype:"orderstatus",            
            }
            this.b2bconn.send(JSON.stringify(socketData))
        
            this.setState({
                customer_cancel: "yes",
                order_status: "customer_canceled"
            });        
        }).catch(function (error) {
               
                if(error.response.status==401){
                     that.props.history.push('/sign-in');
                }
        });
    }

    onMessage(event) {
        const socketData = JSON.parse(event.data)
        if (socketData.requesttype == "orderstatus" && socketData.order_id == this.state.order_id && socketData.status == 'delivery_confirmed') {
           
            const device_id = localStorage.getItem('device_id');
            const requestParam = {
                language_id: 1,
                device_id: device_id,
                order_id: this.props.match.params.id
            }
           
            this.fetchData(requestParam);
        }
        if (socketData.requesttype == "orderstatus" && socketData.order_id == this.state.order_id && socketData.customer_cancel == 'yes') {
            this.setState({
                customer_cancel: 'yes',  
                 order_status:  'cancelled',          
            });
        }
        if (socketData.requesttype == "orderstatus" && socketData.order_id == this.state.order_id && socketData.status == 'shop_confirmed') {
            this.setState({
                order_status:  'shop_confirmed',       
                item_available:  socketData.item_available == 'yes' ? 'yes' : 'no',       
            });
        }

        if (socketData.requesttype == "orderstatus" && socketData.order_id == this.state.order_id && socketData.item_collected == 'yes') {
            this.setState({
                item_collected:  'yes',             
            });
        }

        if (socketData.requesttype == "orderstatus" && socketData.order_id == this.state.order_id && socketData.product_ds_or_not == 'yes') {
            this.setState({
                product_ds_or_not:  'yes',             
            });
        }

    }

    onFileChange = event => {
      this.setState({ selectedFile: event.target.files[0] });
    };

    onFileUpload = () => { 
        const formData = new FormData();    
        formData.append("stamp_file_img",this.state.selectedFile);
        formData.append('order_id',this.state.order_id);
        formData.append('language_id',1);

        axios.post(ORDER_STAMP_UPLOAD, formData,{ 
            headers: {
            'Authorization': localStorage.getItem('device_id')
            }
        }).then((response) => {                    
            this.setState({
                stamp_image: response.data.stamp_image,            
            });               
        }).catch(function (error) {
        });
    };

    render(){
        console.log(this.state.activeCartData.location_name);
        const orderData = this.state.order_data;
        const shopData = this.state.shop_data;
        const deliveryDetails = this.state.delivery_details;
        const cartDetails = this.state.cart_details;
        const selectedCartData = this.state.selectedCartData;
        const isLoading =  this.state.isLoading;
        return(
            <>
                {
                    isLoading == false ? 
                    <>
                    <section className="parallax-window" id="cs_short" style={{backgroundImage: `url(${AWS_IMG_PATH + "extra_images/shop_detail_default_banner.png"})`}}>
                        <div id="subheader">
                            <div id="sub_content">
                            <h1>Order Details</h1>                
                            </div>
                        </div>
                    </section>

                    <div className="container-fluid full-height mt-3 order_details_scn">
                   { 
                    this.state.customer_cancel == 'no' ? 
                        <div className="bs-wizard row">
                        
                            <div className={"col-4 bs-wizard-step " + (this.state.order_status =='pending' ? '' : 'complete')}>
                                <div className="text-center bs-wizard-stepnum"> Order Confirmed</div>
                                <div className="progress">
                                    <div className="progress-bar"></div>
                                </div>
                                <a className={this.state.order_status =='pending' ? '' : 'bs-wizard-dot'}></a>
                            </div>
                            <div className={"col-4 bs-wizard-step " + (this.state.item_collected =='yes' ? 'complete' : '')}>
                                <div className="text-center bs-wizard-stepnum">Item Collected</div>
                                <div className="progress">
                                    <div className="progress-bar"></div>
                                </div>
                                <a className={this.state.item_collected =='yes' ? 'bs-wizard-dot' : ''}></a> 
                            </div>
                            <div className={"col-4 bs-wizard-step " + (this.state.product_ds_or_not =='yes' ? 'complete' : '')}>
                                <div className="text-center bs-wizard-stepnum"> Delivered!</div>
                                <div className="progress">
                                    <div className="progress-bar"></div>
                                </div>
                                <a className = {this.state.product_ds_or_not =='yes' ? 'bs-wizard-dot' : ''}></a>
                            </div>
                        </div>
                    : null }

                    
                   
                    <div className="row row-height">

                        <div className="col-lg-12 content-left">
                            <div className="row row-height">
                                <div className="col-lg-3">
                                    <input id="stamp_file_img" type="file" className="form-control"  onChange={this.onFileChange}></input>
                                   
                                    <button className="img_upload_btn" onClick={this.onFileUpload}>
                                    Upload!
                                    </button>
                                    <span style={{"color":"red"}}>You can upload bill after verify your order and stamp bill</span>
                                </div>  
                                <div className="col-lg-9">
                                    {
                                        this.state.stamp_image &&
                                        <img style={{"width": "50%","height":"180px"}} src={this.state.stamp_image} alt="" />
                                    }
                                    
                                </div>                             
                            </div>
                        </div>
                         
                  
                        <div className={this.state.item_available == 'yes' && this.state.payment_status == "unpaid" ? "col-lg-6 content-left" : "col-lg-8 content-left"}>
                            <div className="row">
                                
                                    {/* {
                                        this.state.order_status != 'shop_confirmed' && this.state.customer_cancel == 'no' && deliveryDetails.length == 0 ? 
                                            <div className="col-lg-6">
                                                <input type="button" className="btn_full red_btn" value="Cancel Order" onClick={this.cancel_order_confirm}></input> 
                                            </div>
                                            : 
                                            null
                                    } */}
                                    {
                                        this.state.product_ds_or_not =='yes' && this.state.order_received == 'no' ?
                                        <div className="col-lg-6">
                                            {/* <input type="button" className="btn_full pushable" value="Received" onClick={this.order_received}></input>  */}
                                            <button className="pushable" onClick={this.order_received}>
                                                <span className="front">
                                                    Received Order
                                                </span>
                                            </button>
                                        </div>
                                        : null
                                    }
                                                        
                            </div>
                            <div className="box_style_2" id="order_process">
                                <div className="row">
                                    <div className="col-lg-6"><h3 className="custom_sub_heading">Order Id: {orderData.id}</h3></div>
                                    <div className="col-lg-6 text-right"><h3 className="custom_sub_heading">Order Status: {this.state.order_status =='pending' ? "PENDING" :  this.state.customer_cancel == 'yes' ? "CANCELLED" : this.state.product_ds_or_not =='yes' ? "DELIVERED" : "CONFIREMD" }</h3></div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="desc" style={{height:"120px"}}>
                                            <div className="thumb_strip">
                                                <img src={shopData.shop_photo} alt="" />
                                            </div>
                                            
                                            <h3 className="cut-text">{shopData.shop_name}</h3>                    
                                            
                                            <div className="type">
                                                Order Id: {orderData.id}
                                                <br />
                                                Order Status: {this.state.order_status =='pending' ? <strong className='text-danger'>PENDING</strong> :  this.state.customer_cancel == 'yes' ? <strong className='text-danger'>CANCELLED</strong> : this.state.product_ds_or_not =='yes' ? <strong className='text-primary'>DELIVERED</strong> : <strong className='text-primary'>CONFIREMD</strong>}
                                                <br/>
                                                Payment Status: {orderData.payment_status =="paid" ? <strong style={{color:"#0a800e"}}>PAID</strong> : <strong className='text-danger'>UNPAID</strong>}
                                                
                                            </div>
                                            <div className="type">
                                                
                                            </div>
                                                                          
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="col-lg-6"> 
                                        {
                                           
                                           deliveryDetails.length !== 0 ? 
                                            <>
                                                <div className="desc" style={{height:"120px"}}>
                                                    <div className="thumb_strip border-0">
                                                        <a href="#"><img className="rounded-circle" src={deliveryDetails.user_photo} alt="" /></a>
                                                    </div>
                                                    
                                                    <h3>{deliveryDetails.user_name}</h3>
                                                    {
                                                        this.state.product_ds_or_not =='no' && 
                                                            <div className="type">
                                                            Mobile: {deliveryDetails.mobile}
                                                            </div>
                                                    }
                                                   
                                                                                
                                                </div>
                                                <hr />
                                            </>
                                            : null
                                        }
                                        
                                     </div>
                                </div>
                                <table className="table nomargin">
                                    <tbody>
                                    {
                                        cartDetails ? 
                                            cartDetails.map( (cartData,cartIndex) => {
                                                let rowCount = 1;
                                                return cartData.weight_products.map( (weightData,weightIndex) => {
                                                    let productType = weightData.product_selling_type;
                                                    return(
                                                        <> 
                                                        {
                                                        weightData.section == 'yes' ? 
                                                        
                                                        <tr>
                                                            <td colSpan="2"><strong>{weightData.product_name} - Addon</strong></td>
                                                        </tr>
                                                                                                        
                                                        : null
                                                        }
                                                        <tr key={weightIndex}>
                                                          
                                                            <td>
                                                                <figure className="thumb_menu_list product_list_image "><img src={weightData.image} alt="thumb" /></figure>
                                                                <h5>{weightData.product_name}</h5>
                                                                <p>
                                                                <strong>{weightData.product_quantity}x{weightData.weight}</strong>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state.price_show == 'yes' && 
                                                                    <strong className="float-right">SAR {weightData.product_fixed_price}</strong>
                                                                }
                                                               
                                                            </td>
                                                        </tr> 
                                                        </>
                                                    )
                                                   
                                                    rowCount = rowCount + 1;
                                                })
                                                
                                            })
                                        : 
                                        null
                                    }
                                        <tr>
                                            <td>Your Location</td>
                                            <td>                                                                                                
                                                <div>
                                                    <div>    
                                                        <h7>{orderData.address_description}</h7>                                                               
                                                        <p>
                                                            {orderData.location}
                                                        </p>
                                                        {
                                                            orderData.product_ds_or_not == 'yes' ?
                                                            <span style={{"color":"#379b05"}}><strong>Delivered ( {orderData.d_delivered_time} )</strong></span>
                                                            : 
                                                            <span className="text-danger"><strong>On the way</strong></span>
                                                        }                                                                                                                        
                                                    </div>
                                                    <hr />
                                                </div>
                                                    
                                                
                                            </td>                                        
                                        </tr>
                                        {
                                            this.state.charged_code != undefined && this.state.charged_code != '' ?
                                            <tr>
                                                <td>
                                                    Charged Code
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.charged_code} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.location_name != undefined && this.state.activeCartData.location_name != '' ?
                                            <tr>
                                                <td>
                                                    Location Name
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.location_name} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.location_type != undefined && this.state.activeCartData.location_type != '' ?
                                            <tr>
                                                <td>
                                                    Location type
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.location_type} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.location_comments != undefined && this.state.activeCartData.location_comments != '' ?
                                            <tr>
                                                <td>
                                                    Location comments
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.location_comments} 
                                                </td>
                                            </tr> : null
                                        }                                        
                                        {
                                            this.state.activeCartData.customer_contact_info != undefined && this.state.activeCartData.customer_contact_info != '' ?
                                            <tr>
                                                <td>
                                                    Customer contact info
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.customer_contact_info} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.driver_contact_info != undefined && this.state.activeCartData.driver_contact_info != '' ?
                                            <tr>
                                                <td>
                                                    Driver contact info
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.driver_contact_info} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.supplied_contact_info != undefined && this.state.activeCartData.supplied_contact_info != '' ?
                                            <tr>
                                                <td>
                                                    Supplier contact info
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.supplied_contact_info} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.complex_name != undefined && this.state.activeCartData.complex_name != '' ?
                                            <tr>
                                                <td>
                                                    Complex name
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.complex_name} 
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            this.state.activeCartData.gate_no != undefined && this.state.activeCartData.gate_no != '' ?
                                            <tr>
                                                <td>
                                                    Gate No
                                                </td>
                                                <td classNamea="text-right">
                                                    {this.state.activeCartData.gate_no} 
                                                </td>
                                            </tr> : null
                                        }
                                                                                                               
                                        <tr>
                                            <td>
                                                Driver Accepted
                                            </td>
                                            <td className="text-right">
                                                {this.state.d_confirm_time} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Arrived to shop
                                            </td>
                                            <td className="text-right">
                                                {this.state.arrived_time} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Item collected
                                            </td>
                                            <td className="text-right">
                                                {this.state.item_collected_time} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Delivered
                                            </td>
                                            <td className="text-right">
                                                {this.state.d_delivered_time} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Payment Type: 
                                            </td>
                                            <td className="text-right">
                                                <>
                                                <strong>
                                                {this.state.payment_method_label} 
                                                {
                                                    orderData.credit_date &&  <strong> ( { orderData.credit_date } ) </strong>
                                                }
                                                </strong>
                                                {
                                                selectedCartData.card_number && this.state.payment_code != 'cod' ?
                                                <div className="credit-card-show-last-detail"> { selectedCartData.card_number.substr(selectedCartData.card_number.length - 4) }  </div>
                                                : null                                               
                                                }
                                                </>
                                            </td>
                                        </tr>   
                                        <tr>
                                            <td>
                                                Delivery Time: 
                                            </td>
                                            <td className="text-right">
                                                <strong>
                                                {orderData.schedule_order == 'yes' ?
                                                 "Schedule - "+ orderData.schedule_date
                                                : "Now"}
                                                </strong>
                                            </td>
                                        </tr>  
                                        {
                                            orderData.special_request_note &&
                                            <tr>
                                            <td>
                                                Notes for the restaurant: 
                                            </td>
                                            <td className="text-right">
                                                <strong>
                                                {orderData.special_request_note }
                                                </strong>
                                            </td>
                                            </tr> 
                                        }
                                      
                                        {
                                            this.state.price_show == 'yes' && 
                                            <>
                                             <tr>
                                                <td>
                                                    Sub Total: 
                                                </td>
                                                <td className="text-right">
                                                    <strong>
                                                        SAR {this.state.subtotal}
                                                    </strong>
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td>
                                                    Delivery Cost: 
                                                </td>
                                                <td className="text-right">
                                                    <strong>
                                                        SAR {this.state.delivery_cost}
                                                    </strong>
                                                </td>
                                            </tr>
                                             <tr>
                                            <td className="total_confirm">
                                                TOTAL
                                            </td>
                                            <td className="total_confirm">
                                                <span className="float-right">SAR {this.state.final_amount}</span>
                                            </td>
                                            </tr>
                                            </>
                                        }
                                        
                                        
                                        
                                          
                                       
                                    </tbody>
                                </table>                                
                            </div>
                        </div>   
                        
                        {
                            this.state.item_available == 'yes' && this.state.payment_status == "unpaid" &&
                            <div className="col-lg-3">
                                <div className="box_style_2" id="payment_process">

                                    {
                                        this.state.selectedCartData.card_number && 
                                        <>
                                        <Cards
                                            cvc={this.state.selectedCartData.cvc}
                                            expiry={this.state.selectedCartData.exp_month}
                                            name={this.state.selectedCartData.card_name}
                                            number={this.state.selectedCartData.card_number}   
                                            focused={this.state.selectedCartData.focused}                                 
                                        />

                                        <form onSubmit={this.getCheckoutId}>
                                        
                                            <div className="form-group">                                        
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            id="ccv" 
                                                            name="cvc" 
                                                            className="form-control" 
                                                            placeholder="CCV" 
                                                            maxLength="3"
                                                            pattern=".{3,}"
                                                            required="required"
                                                            value={this.state.cvc}                                                       
                                                            onChange={this.handleInputCvvChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">                                                
                                                        <input type="submit" className="btn_full" value={"PAY SAR "+ this.state.final_amount} name="add_card" />
                                                    </div>
                                                </div>                                            
                                            </div>                                    
                                        </form>
                                     
                                        <form id="payForOrder" action="#" method="post" lang="en">
                                            <input type="hidden" id="shopperResultUrl" name="shopperResultUrl" value="" />                                                                                                                    
                                            <input type="hidden" name="card.expiryMonth" value={this.state.selectedCartData.expire_month} />
                                            <input type="hidden" name="card.expiryYear" value={"20"+this.state.selectedCartData.expire_year} />
                                            <input type="hidden" name="paymentBrand" value={this.state.selectedCartData.card_number.charAt(0) == 4 ? "VISA" : "MASTER"} />
                                            <input type="hidden" name="card.cvv" value={this.state.cvc} />                                                    
                                            <input type="hidden" name="card.number" value={this.state.selectedCartData.card_number.replace(/\s/g,'')} />
                                            <input type="hidden" name="card.holder" value={this.state.selectedCartData.card_name} />                                       
                                        </form>
                                        </>
                                    }           
                                    

                                    <div className="pm_list_container">
                                        {
                                            this.state.paymentMethodList.map( (methodData,methodKey) => {
                                                return(                                                                                                
                                                <div className="payment_select" key={methodKey}>
                                                    <label>
                                                        <Radio
                                                        name="payment_code" 
                                                        radioClass="iradio_square-grey"   
                                                        data-payment_method_name={methodData.payment_method_name}                                                
                                                        label={methodData.payment_method_name}
                                                        value={methodData.payment_method_code}
                                                        onClick={this.handlePaymentChange} 
                                                        checked={this.state.payment_code == methodData.payment_method_code}
                                                        />
                                                        
                                                    </label>
                                                    <i class={methodData.payment_method_img_on}></i>
                                                </div>
                                                ) 
                                            })
                                        }
                                    </div>
                                    <div className="credit_Card_container">
                                    { 
                                        this.state.payment_code == 'card' || this.state.payment_code == 'mada' ?
                                        <>
                                        {
                                            this.state.add_card_toggle == false &&
                                            <div className="form-group">
                                                <input type="button" className="btn_full" name="add_card_toggle" value="ADD CARD" onClick={this.add_card_toggle} />
                                            </div>
                                        }
                                        <div className="form-group">
                                                    
                                            {
                                                this.state.add_card_toggle == false &&
                                                this.state.customerCardList.map( (cardData,cardKey) => {
                                                    
                                                    return(     
                                                        <>
                                                        {   
                                                        this.state.payment_code == cardData.card_type &&
                                                            <a href="#" key={cardData.id} data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard}>                                                                                           
                                                                <div  data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard} className={cardData.card_number.charAt(0) == 4 ? " credit-card selectable visa" : "credit-card selectable mastercard"}>
                                                                    <div className="credit-card-last4"  data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard}>
                                                                        { cardData.card_number.substr(cardData.card_number.length - 4)  }
                                                                    </div>
                                                                    <div className="credit-card-expiry" data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard}>
                                                                        {cardData.exp_month}
                                                                    </div>
                                                                </div>                               
                                                            </a>
                                                        }
                                                        </>
                                                    ); 
                                                
                                                })
                                            }
                                        </div>

                                        <div className="add_card_toggle">
                                        {
                                            this.state.add_card_toggle == true ?
                                        
                                            <div className="form-group add_card_toggle">

                                                <div className="form-group">                      
                                                <Cards
                                                    cvc={this.state.cvc_add}
                                                    expiry={this.state.expire_month}
                                                    name={this.state.name_card_order}
                                                    number={this.state.card_number}   
                                                    focused={this.state.focused}                                 
                                                />
                                                </div>
                                            
                                                <form onSubmit={this.addCardSubmit}>
                                                    <div className="form-group">
                                                        <label>Name on card</label>
                                                        <input 
                                                            type="text" 
                                                            className="form-control"
                                                            id="name_card_order" 
                                                            name="name_card_order" 
                                                            placeholder="First and last name" 
                                                            required="required"         
                                                            value={this.state.name_card_order}    
                                                            onFocus={this.handleInputFocus}                          
                                                            onChange={this.handleInputNameChange}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Card number</label>                                
                                                        <input 
                                                            type="text" 
                                                            id="card_number" 
                                                            name="card_number" 
                                                            className="form-control"                                             
                                                            maxLength="19"  
                                                            pattern=".{19,}"                                          
                                                            required="required"
                                                            value={this.state.card_number}
                                                            placeholder="xxxx xxxx xxxx xxxx" 
                                                            onFocus={this.handleInputFocus} 
                                                            onChange={this.handleInputCardNumberChange}/>

                                                    </div>

                                                    <div className="row">
                                                    <div class="col-md-6">
                                                        <label>Expiration date</label>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <div className="form-group">
                                                                    <input 
                                                                        type="text" 
                                                                        name="expire_month" 
                                                                        id="expire_month" 
                                                                        className="form-control" 
                                                                        placeholder="12/21" 
                                                                        maxLength="5" 
                                                                        pattern=".{5,}"
                                                                        required="required"
                                                                        value={this.state.expire_month}
                                                                        onFocus={this.handleInputFocus} 
                                                                        onChange={this.handleInputExpireChange}/>
                                                                </div>
                                                            </div>
                                                
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Security code</label>
                                                            <div className="row">
                                                                <div className="col-md-6 col-sm-6">
                                                                    <div className="form-group">
                                                                        <input 
                                                                            type="text" 
                                                                            id="ccv" 
                                                                            name="cvc_add" 
                                                                            className="form-control" 
                                                                            placeholder="CCV" 
                                                                            maxlength="3"
                                                                            pattern=".{3,}"
                                                                            required="required"
                                                                            value={this.state.cvc_add}
                                                                            onFocus={this.handleInputFocus}
                                                                            onChange={this.handleInputAddCvvChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6">
                                                                    <img src={AWS_IMG_PATH + "extra_images/icon_ccv.gif"} width="50" height="29" alt="ccv" /><small>Last 3 digits</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="submit" className="btn_full" value="ADD CARD" name="add_card" />
                                                        <input type="button" className="btn_full" value="CANCEL" name="cancel_add_card"  onClick={this.cancel_add_card_toggle}/>
                                                    </div>
                                                    <hr />
                                                    </div>
                                                </form>
                                            </div>
                                            : null
                                        }   
                                        </div>
                                        </> : null                             
                                    }
                                    </div>
                                </div>
                            </div>
                        }
                        
                        <div className={this.state.item_available == 'yes' && this.state.payment_status == "unpaid" ? "col-lg-3 box_style_2 content-right" : "col-lg-4 box_style_2 content-right"}>
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="custom_sub_heading">Help Center</h3>
                                    <div className="help_center_container">
                                        <input type="button" className="btn_full" data-help="1" value="Chat with support" onClick={this.helpToggle}></input>                                                                             
                                        {
                                            <>
                                                {/* <input type="button" className="btn_full" data-help="2" value="Chat with Driver" onClick={this.helpToggle}></input> */}
                                                
                                                <input type="button" className="btn_full" data-help="3" value="Track your order" onClick={this.helpToggle}></input>
                                                {
                                                    this.state.product_ds_or_not == 'yes' &&
                                                    <>
                                                    <input type="button" className="btn_full" data-flag="2" value="Issue with driver" onClick={this.driverIssue}></input>
                                                    <input type="button" className="btn_full" data-flag="2" value="Issue with order" onClick={this.orderIssue}></input> 
                                                    </>
                                                }
                                                
                                            </>
                                        }
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            {
                                true ? //this.state.product_ds_or_not =='no'
                                <div id="help_sidebar" className="row">
                                    {
                                        this.state.helpType == 1 ? 
                                            <div className="col-12 order_tracking_container">                                                     
                                                <h3 className="custom_sub_heading mt-3">Chat with support</h3>
                                                 <ChatWithAdmin />    
                                            </div>
                                        : this.state.helpType == 2 ? 
                                            <div className="col-12 order_tracking_container">                                                     
                                                <h3 className="custom_sub_heading mt-3">Chat with driver</h3>
                                                 <ChatWithAdmin />    
                                            </div>
                                        : this.state.helpType == 3 ?
                                            <div className="col-12 order_tracking_container">                                                     
                                                <h3 className="custom_sub_heading mt-3">Order Tracking</h3>
                                                <div id="map_ing1">
                                                    { typeof deliveryDetails.gps_latitude !== 'undefined' ? 
                                                        <MapWithADirectionsRenderer key={this.state.unique_id} customerLat={orderData.shop_latitude} customerLng={orderData.shop_longitude} shopLat={shopData.shop_latitude} shopLng={shopData.shop_longitude} driverLat={deliveryDetails.gps_latitude} driverLng={deliveryDetails.gps_longitude}/>
                                                    : 
                                                        <div><h4>Order tracking will be available once driver confirm your order.</h4></div>
                                                        
                                                    }
                                                </div>  
                                            </div>
                                        : null
                                    }
                                    
                                </div> : null
                            }
                                          
                        </div>
                    </div> 
                </div>
                
                    </> : <OrderDetailSkeleton />
                }
            </>
        )
    }
}

export default OrderDetail;