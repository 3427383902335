import React, { Component } from 'react';
import { MAP_GOOGLE_KEY } from '../Comman/Constant';
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker
  
} = require("react-google-maps");

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+ MAP_GOOGLE_KEY + "&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `800px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    
    componentDidMount() {
        const directionsService = new window.google.maps.DirectionsService();
        const  DirectionsDisplay = new window.google.maps.DirectionsRenderer({suppressMarkers: true},{strokeColor:"#4a4a4a"});
        const origin = { lat: parseFloat(this.props.customerLat), lng:  parseFloat(this.props.customerLng) };
        const destination = {lat: parseFloat(this.props.shopLat), lng: parseFloat(this.props.shopLng)};
        const google = window.google;

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
           
            },
            (response, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                   
                    DirectionsDisplay.setDirections( response );
                    var leg = response.routes[ 0 ].legs[ 0 ];
                    
                    this.setState({
                        directions: response,
                        originLat: leg.start_location.lat(),
                        originLng: leg.start_location.lng(),
                        destiLat: leg.end_location.lat(),
                        destiLng: leg.end_location.lng(),
                    });
                } else {
                    console.error(response);
                }
            }
        );
    }
  })
)(props =>
  <>
    { typeof props.driverLat !== 'undefined' ? 
      <GoogleMap
        defaultZoom={16}
        defaultCenter={new window.google.maps.LatLng(parseFloat(props.customerLat), parseFloat(props.customerLng))}
      >

        <Marker
            position={{ lat: parseFloat(props.driverLat), lng: parseFloat(props.driverLng) }}
            icon={{
              url:  "https://d7q1bcuj8cly9.cloudfront.net/extra_images/447-48.png",            
            }}
          />

        <Marker
            position={{ lat: parseFloat(props.originLat), lng: parseFloat(props.originLng) }}
            icon={{
              url:  "https://d7q1bcuj8cly9.cloudfront.net/extra_images/icon6.png",            
            }}
          />

        <Marker
            position={{ lat: parseFloat(props.destiLat), lng: parseFloat(props.destiLng) }}
            icon={{
              url:  "https://d7q1bcuj8cly9.cloudfront.net/extra_images/icon21.png",            
            }}
          />
          

      {props.directions && 
      
      <DirectionsRenderer
            directions={props.directions}
            options={{
              polylineOptions: {
                stokeColor: "#FF0000",
                strokeOpacity: 0.5,
                strokeWeight: 4
              },
              markerOptions: { icon: "https://d7q1bcuj8cly9.cloudfront.net/extra_images/447-48.png" },
              preserveViewport: true,
              suppressMarkers: true,
              icon: { scale: 3 }
            }}
          />
      }
      </GoogleMap>
    : <div><h4>Order Tracking availabel once driver availabel for your order.</h4></div>  }
  </>
);

export default MapWithADirectionsRenderer;