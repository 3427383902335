import React,  { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';

import Home from './component/Home/index';
import Header from './component/Comman/Header';
import Footer from './component/Comman/Footer';
import ShopDetail from './component/ShopsList/shopDetail';
import productDetail from './component/ShopsList/productDetail';
import OrderStepTwo from './component/Orders/stepTwo';
import OrderStepOne from './component/Orders/stepOne';
import OrderPlaced from './component/Orders/orderPlaced';
import OrderList from './component/Orders/orderList';
import OrderDetail from './component/Orders/orderDetail';
import CustomerLocationSelector from './component/Widget/customerLocationSelect';
import OrderLocationSelector from './component/Widget/orderLocationSelect';
import AddLocation from './component/Widget/addCustomerLocation';
import Signin from './component/Widget/Signin';
import Signup from './component/Widget/Signup';
import Landing from './component/StaticPage/Landing';

import $ from "jquery";


function userRedirectToLocationPage () {
    const device_id = localStorage.getItem('device_id');    
    if(!device_id){      
        if( window.location.pathname == '/sign-in' || window.location.pathname == '/sign-up' || window.location.pathname.indexOf('change_address') === 1) {
             
        } else {
            return <Redirect to='/landing'/>  
        } 
    }

    const customerLat = localStorage.getItem('customerLat');
    if(!customerLat){    
        localStorage.setItem('customerLat', 24.7120338);
        localStorage.setItem('customerLng', 46.6714201);                
    }
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const Routes = (props) => {
    return (
        <BrowserRouter> 
            {userRedirectToLocationPage()}
            <ScrollToTop />
            <Header />
            <Switch>
                <Route path="/change_address/:id" component={OrderLocationSelector} exact/>
                <Route path="/shop/:id" component={ShopDetail} exact/>
                <Route path="/shop/:id/detail" component={productDetail}/>
                <Route path="/order_detail/:id" component={OrderDetail}/>
                <Route path="/order_step_one" component={OrderStepOne}/>
                <Route path="/order_step_two" component={OrderStepTwo}/>
                <Route path="/order_place/:id" component={OrderPlaced}/>
                
                <Route path="/order_list" component={OrderList}/>    
                <Route path="/login" component={Signin} /> 
                <Route path="/sign-in" component={Signin} /> 
                <Route path="/sign-up" component={Signup} /> 
                <Route path="/your_location" component={CustomerLocationSelector} />  
                <Route path="/add_location" component={AddLocation} /> 
                <Route path="/landing" component={Landing} />                 
                <Route path="/" component={Home} exact/>
                 
            </Switch>
            <Footer />
        </BrowserRouter>
    )
};

export default Routes;

$(".main-menu ul li a").on("click", function() {
    $(".cmn-toggle-switch").removeClass("active")
    $(".main-menu").removeClass("show")
    $(".layer").removeClass("layer-is-visible")
  });