import React, { Component } from 'react';
import axios from "axios";
import { Redirect  } from 'react-router-dom';

import HomeSlider from './Slider';
import ShopListItem from '../ShopsList/singleShopRow';
import CategorySlider from './categorySlider';
import ShopListMap from './ShopMapList';
import HomePageSkeleton from '../skeleton/HomePageSkeleton';


import {HOME_API_URL} from '../Comman/Constant';

class Home extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            shops_info: [],
            banner_list: [],
            shops_category: [],
            isLoading: true,
        }
    }

    componentDidMount(){
        const device_id = localStorage.getItem('device_id');
        const requestParam = {
            language_id: 1,
            cust_lat: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            cust_lng: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            device_id: device_id,
            category_code: ""
        }

        this.fetchData(requestParam);
    }

    fetchData = async (requestParam) => {

        localStorage.removeItem('schedule_order');
        localStorage.removeItem('schedule_slot');
        localStorage.removeItem('schedule_slot_date');
        localStorage.removeItem('delivery_type');

        this.setState({
            isLoading: true,                
        });

        var that = this;

        await axios.post(HOME_API_URL, requestParam,            
            { 
                headers: {
                'Authorization': requestParam.device_id 
                }
            })
            .then((response) => {            
            this.setState({
                shops_info: response.data.shops_info,
                banner_list: response.data.banner_list,
                shops_category: response.data.shops_category,
                unique_id: response.data.unique_id,
                isLoading: false,   
            });        
            }).catch(function (error) {        
                console.log(error)    
            });
    }

    catgoeryFilter = (param) => {
        
        const device_id = localStorage.getItem('device_id');
        const requestParam = {
            language_id: 1,
            cust_lat: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            cust_lng: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            device_id: device_id,
            category_code: param
        }

        this.fetchData(requestParam);
    }

    userRedirectToLocationPage = () => {
        const customerLat = localStorage.getItem('customerLat');
       
        const device_id = localStorage.getItem('device_id');
        if(!device_id){            
            return <Redirect to='/landing'/>    
        }
        
        if(!customerLat){    
            localStorage.setItem('customerLat', 24.7120338);
            localStorage.setItem('customerLng', 46.6714201);                
        }
    }

    render(){
      
       const shops_info = this.state.shops_info;
       let banner_list = this.state.banner_list;
       const isLoading =  this.state.isLoading;
        
        return(
            <>
            {this.userRedirectToLocationPage()}
            
            {
                isLoading == false ? 
                <>
                    <div className="home_slider">
                        <HomeSlider banner_list={banner_list}/>
                    </div>
                    <div className="catgory_slider">
                        {/* <CategorySlider categoryFunction={this.catgoeryFilter} shops_category={this.state.shops_category}/> */}
                    </div>
        
                    <div className="container-fluid full-height mt-3">
                            <div className="row row-height">
                                <div className="col-lg-12" >
                                    { shops_info.map( (shopData,i) => {                                                                
                                            return(                                
                                                <ShopListItem key={shopData.shop_id} shop_info={shopData}/>
                                            )
                                        })
                                    }
                                </div>
                                {/* <div className="col-lg-5 map-right"> 
                                    
                                        {
                                            shops_info.length !== 0 ? <ShopListMap key={this.state.unique_id} shop_data={shops_info}/>   : null 
                                        }                       
                                        
                                </div> */}
                            </div>
                    </div>
                </> : <HomePageSkeleton />
            }
            

            </>
        )
    }
}

export default Home;