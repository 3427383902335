import React, { Component } from 'react';
import axios from "axios";

import { CUSTMER_CHAT, B2BSOCKET_URL, AWS_IMG_PATH } from '../Comman/Constant';

class  ChatWithAdmin extends React.Component{

    constructor(){
        super();    
        this.state = {    
            chat_history: []
        }
    }

    componentDidMount(){

        const device_id = localStorage.getItem('device_id');
        this.b2bconn = new WebSocket(B2BSOCKET_URL);
        this.b2bconn.onmessage = this.onMessage.bind(this);     
        const requestParam = {
            language_id: 1,
            device_id: device_id,           
        }
        this.fetchChatHistory(requestParam);
    }

    fetchChatHistory = (requestParam) => {
    
         axios.post(CUSTMER_CHAT, requestParam)
        .then((response) => {       
             this.setState({
                chat_history: response.data.code == 200 ? response.data.chat_details : [],
                customer_name: response.data.customer_name ? response.data.customer_name : ""
            });   
             const element = document.getElementById("chat_history_container");    
              element.scrollTop = element.scrollHeight; 
        });
    }

    sendChatMsgOnEnter = (e) => {         
        if (e.key === 'Enter') {
            this.sendChatMsg();
        }
    }

    sendChatMsg = () => {

        if(this.state.message_text == undefined){
            return false;
        } 
        var message_text = this.state.message_text.replace(/ /g,'');
        if(message_text == ""){
            return false;
        } 
        const fromHTmlSample = document.getElementById("fromHTmlSample").outerHTML ;    
        const element = document.getElementById("chat_history_container");
        var changemsg = fromHTmlSample.replace("text_goes_here",this.state.message_text);   

        var now = new Date().toLocaleString("en-US", {timeZone: "Asia/Riyadh"});
        var fullYear = new Date(now).getFullYear();
        var getMonth = new Date(now).getMonth() < 9 ? "0" + (new Date(now).getMonth() + 1) : (new Date(now).getMonth() + 1);
        var getDate = new Date(now).getDate() < 10 ? "0" + new Date(now).getDate() : new Date(now).getDate();
        var getHours = new Date(now).getHours() < 10 ? "0" + new Date(now).getHours() : new Date(now).getHours();
        var getMinutes = new Date(now).getMinutes() < 10 ? "0" + new Date(now).getMinutes() : new Date(now).getMinutes();
        var getSeconds = new Date(now).getSeconds() < 10 ? "0" + new Date(now).getSeconds() : new Date(now).getSeconds();

        var fullDate = fullYear +"-"+ getMonth +"-"+ getDate + " " + getHours +":"+ getMinutes +":"+ getSeconds;
        var changemsg = changemsg.replace("date_goes_here",fullDate);   
        element.insertAdjacentHTML('beforeend',changemsg);
        element.scrollTop = element.scrollHeight;
        this.setState({
            message_text: ""
        });
        document.getElementById("message_text").focus();
        const device_id = localStorage.getItem('device_id');
        const customer_mobile = localStorage.getItem('customer_mobile');
        const customer_firstname = localStorage.getItem('firstname_order');
        const socketData = {        
                device_id: device_id,
                username: this.state.customer_name,
                usermobile: customer_mobile,
                from:"customer", 
                message:this.state.message_text, 
                requesttype:"chatsupport",                            
            }
        this.b2bconn.send(JSON.stringify(socketData))

    }

    onMessage(event) {
        const socketData = JSON.parse(event.data)
        const device_id = localStorage.getItem('device_id');


        if (socketData.device_id == device_id && socketData.requesttype == "chatsupport" && socketData.from == 'admin') {
            const toHTmlSample = document.getElementById("toHTmlSample").outerHTML ;    
            const element = document.getElementById("chat_history_container");
            var changemsg = toHTmlSample.replace("text_goes_here",socketData.message);   
            var changemsg = changemsg.replace("date_goes_here",socketData.datetime);   
            element.insertAdjacentHTML('beforeend',changemsg);
            element.scrollTop = element.scrollHeight;
        }

    }

    handleChatMessage = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }


    render(){
         const chat_history = this.state.chat_history;
          return(
            <div className="chat_container">
                <div style={{"display":"none"}}>
                     <li id="fromHTmlSample" style={{"width":"100%"}} >
                        <div className="msj-rta macro">
                            <div className="avatar">
                                <img className="img-circle chat_image_person" style={{"width":"100%;"}} src={AWS_IMG_PATH + "extra_images/customer_chat.svg"} />                            
                            </div>
                            <div className="text text-r">
                                <p className="client_msg">text_goes_here</p>
                                <p><small className="client_date">date_goes_here</small></p>
                            </div>
                        </div>
                    </li>
                    <li id="toHTmlSample" style={{"width":"100%"}} >
                        <div className="msj macro">
                            <div className="avatar">
                                <img className="img-circle chat_image_person" style={{"width":"100%;"}} src={AWS_IMG_PATH + "extra_images/admin_chat.svg"} />                            
                            </div>
                            <div className="text text-l">
                                <p className="client_msg">text_goes_here</p>
                                <p><small className="client_date">date_goes_here</small></p>
                            </div>
                        </div>
                    </li>
                </div>
               
                <ul id="chat_history_container">
                    
                    {
                        chat_history.length != 0 ?
                         chat_history.map( (chatData,chatKey) => {
                            return( 
                                
                                    <li key={chatKey} style={{"width":"100%"}}>
                                        <div className={chatData.from == "admin" ? "msj macro" : "msj-rta macro"}>
                                            <div className="avatar">
                                                <img className="img-circle chat_image_person" style={{"width":"100%;"}} src={chatData.from == "admin" ? AWS_IMG_PATH + "extra_images/admin_chat.svg" : AWS_IMG_PATH + "extra_images/customer_chat.svg"} />
                                            </div>
                                            <div className="text text-l" className={chatData.from == "admin" ? "text text-l" : "text text-r"}>
                                                <p>{chatData.message}</p>
                                                <p><small>{chatData.datetime}</small></p>
                                            </div>
                                        </div>
                                    </li>
                            )
                       
                        }) 
                        : null
                    } 
                </ul>
                <div>
                    <div className="msj-rta1 macro" style={{"margin":"auto"}}>                        
                        <div className="text" style={{"background":"whitesmoke !important"}}>
                            <div className="row">
                                <div className="col-lg-10 nopadding"><input className="mytext" id="message_text" name="message_text" value={this.state.message_text} placeholder="Type a message" onChange={this.handleChatMessage} onKeyPress={this.sendChatMsgOnEnter}/></div>
                                <div className="col-lg-2 nopadding"> <button className="chat_btn_send" value="SEND" onClick={this.sendChatMsg} >SEND</button></div>
                            </div>
                            
                           
                        </div> 
                    </div>
                </div>
            </div>
          )
            
    }
}

export default ChatWithAdmin;