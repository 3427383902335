import "./Skeleton.css";
import React from "react";

const OrderDetailSkeleton = () => {
  
  return (
        <div className="skeleton-homeContainer">

            <section className="skeleton-parallax-window">
                
            </section>

            <div className="container-fluid full-height mt-3">
              
                <div className="skeleton-bs-wizard row bs-wizard"></div>

                <div className="row row-height">
                    <div className="col-lg-6 content-left skeleton-col-lg-6"></div>
                    <div className="col-lg-3 skeleton-col-lg-3"></div>
                    <div className="col-lg-3 skeleton-col-lg-3"></div>
                </div>
              
            </div>
           

        </div>
  );
};

export default OrderDetailSkeleton;