import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ShopListItem extends Component {

    constructor(props){
        super(props)

        this.state = {
            shopData: this.props.shop_info
        }
    }

    render(){
        const shopData =  this.state.shopData;
        const ratingHtml = [];
        let remainRating = 0;
        {
            for(let i = 1; i < shopData.shop_rating; i++) 
            {
                ratingHtml.push(<i key={i} className="icon_star voted"></i>);
            }

            remainRating = 5 - ratingHtml.length;

            for(let i = 0; i < remainRating ; i++) 
            {
                ratingHtml.push(<i key={i} className="icon_star"></i>);
            }
        }
        return(
            <>
                 <div className={`strip_list ${shopData.shopParentCatId}`} >
                     {
                         shopData.promoted == 0 ? <div className="ribbon ribbon-top-left"><span style={{color:shopData.premium_innertext_color,backgroundColor:shopData.premium_text_color}}>{shopData.premium_text}</span></div> : null
                     }
                 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="desc">
                                <div className="thumb_strip">
                                <Link to={`/shop/${shopData.shop_id}`}><img src={shopData.image} alt="" /></Link>
                                </div>   
                                <Link to={`/shop/${shopData.shop_id}`}>                           
                                <h3>{shopData.shop_name}</h3>
                                </Link>  
                                <div className="rating">                                
                                  {ratingHtml}
                                </div>
                                <div className="type">
                                    { shopData.shop_category }
                                </div>
                                <div className="home_location_label">
                                    Delivery Time: 60-90 mins | {shopData.minKM} 
                                </div>
                                <ul className="home_time_label">                                   
                                    { 
                                        shopData.shop_status == 'open' ? 
                                            <li>
                                                {shopData.displayTime}
                                                <i className="icon_check_alt2 ok"></i>
                                            </li>  
                                        :  null
                                    }

                                    <li>
                                        <span className="opening1">Schedule your order</span>
                                        <i className="icon_check_alt2 ok"></i>
                                    </li>
                                
                                </ul>
                            </div>
                            </div>
                            <div className="col-md-6">
                                <div className="go_to">
                                    <div>
                                        <Link to={`/shop/${shopData.shop_id}`} className="btn_1">View Menu</Link>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> 
            </>
        )
    }
}

export default ShopListItem;