import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { LOGIN_API } from '../Comman/Constant';

class Signin extends React.Component{

    constructor(props){
        super(props);
      
        this.state = {                          
            isLoading: false,    
            redirect_to: new URLSearchParams(this.props.location.search).get("redirect_to"),        
        };   
            
    }

    onChageHandle = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        const name_error = name + "_error";
       
        this.setState(
            {
                [event.target.name]: value,
                [name_error]: ""
            }        
        );
    }

    doLogin = () => {
        this.setState({                              
            isLoading: true,
        });
        let email_error = !this.state.email ? "Userame can't be blank." : "";    
        let password_error = !this.state.password ? "Password can't be blank." : "";    

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
            email_error = "Please enter valid email address."
        }


        if(email_error || password_error){
            this.setState({        
                email_error: email_error,
                password_error: password_error,   
                isLoading: false,            
            });
        } else {
            const device_id = localStorage.getItem('device_id');
            const requestParam = {
                language_id: 1,        
                email: this.state.email,
                password: this.state.password,
                device_id: device_id
            }
            axios.get(LOGIN_API, {
                params: requestParam
            }).then((response) => {            
                if(response.data.success == true){
                    localStorage.setItem('device_id', response.data.device_id);
                    localStorage.setItem("customer_mobile",response.data.mobile);
                    //this.props.history.push('/');
                    this.state.redirect_to == 1 ? window.location.href = "/order_step_one" : window.location.href = "/"                            
                } else {
                    this.setState({
                        email_error: email_error,
                        password_error: password_error,   
                        error: "Incorrect email or passoword.",               
                        isLoading: false,
                    });
                }
            });
        }
    }

    render(){
        const isLoading =  this.state.isLoading;
        return(
            <div className="row login_rw_scn">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <div className="d-flex justify-content-center align-items-center container margin_60_35 login_container">
                            <div className="card py-5 px-3">
                                <div class="login_icon" style={{color:"#5A368D",textAlign:"center"}}><i class="icon_lock_alt"></i></div>                                                                                      
                                <div class="wrapper_indent">
                                    <div class="row">
                                       
                                        <div class="col-sm-12 col-lg-12">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="email" id="street_1" name="email" class="form-control" onChange={this.onChageHandle} value={this.state.email}/>
                                                 <span className="text-danger">{this.state.email_error}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-lg-12">
                                            <div class="form-group">
                                                <label>Password</label>
                                                <input type="password" id="street_1" name="password" class="form-control" onChange={this.onChageHandle} value={this.state.password}/>
                                                <span className="text-danger">{this.state.password_error}</span>
                                            </div>
                                        </div>

                                         {
                                            this.state.error && 
                                             <div class="col-sm-12 col-lg-12 mt-3 text-center">
                                                <span className="p-2 text-light rounded-10 bg-danger" style={{borderRadius: "5px"}}>{this.state.error}</span>
                                            </div>
                                        }

                                        <div class="col-sm-12 col-lg-12">
                                            <div class="form-group">

                                                {
                                                    isLoading == false ? <input type="submit" className="btn_full deliver_here_btn" value="LOGIN" onClick={this.doLogin}></input>
                                                    :  <a className="btn_full1 skeleton-btn" href=""></a>
                                                }

                                               
                                            </div>
                                        </div>                                    
                                    </div>
                                     <div class="row">
                                         <div class="col-sm-4 col-lg-4">
                                             <Link to=""><strong>Forgot Password?</strong></Link>
                                         </div>
                                         <div class="col-sm-8 col-lg-8 text-right">
                                             <Link to="/sign-up"><strong>Don't have an account? Sign up here</strong></Link>
                                         </div>
                                     </div>
                                </div>                
                            </div>     
                        </div>
                    </div>
                  <div className="col-lg-4"></div>
                </div>
          );
    }

}

export default Signin;