import "./Skeleton.css";
import React from "react";

const OrderListSkeleton = () => {
  
  return (
        <div className="skeleton-homeContainer">

            <section className="skeleton-parallax-window">
                
            </section>

            <div className="container margin_60_35">
                <div className="strip_list">                        
                    <div className="row">
                        <div className="col-md-9">
                            <div className="desc">
                                <div className="skeleton-thumb_strip">
                                
                                </div>
                                <h3 className="skeleton-shop_name"></h3>
                                <div className="rating">
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                </div>
                                <div className="type"></div>
                                <div className="skeleton-shop_desc1"></div>
                                <div className="skeleton-shop_desc2"></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="go_to">
                                <div><a class="skeleton-btn_1" href="#"></a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip_list">                        
                    <div className="row">
                        <div className="col-md-9">
                            <div className="desc">
                                <div className="skeleton-thumb_strip">
                                
                                </div>
                                <h3 className="skeleton-shop_name"></h3>
                                <div className="rating">
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                </div>
                                <div className="type"></div>
                                <div className="skeleton-shop_desc1"></div>
                                <div className="skeleton-shop_desc2"></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="go_to">
                                <div><a class="skeleton-btn_1" href="#"></a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip_list">                        
                    <div className="row">
                        <div className="col-md-9">
                            <div className="desc">
                                <div className="skeleton-thumb_strip">
                                
                                </div>
                                <h3 className="skeleton-shop_name"></h3>
                                <div className="rating">
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                </div>
                                <div className="type"></div>
                                <div className="skeleton-shop_desc1"></div>
                                <div className="skeleton-shop_desc2"></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="go_to">
                                <div><a class="skeleton-btn_1" href="#"></a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip_list">                        
                    <div className="row">
                        <div className="col-md-9">
                            <div className="desc">
                                <div className="skeleton-thumb_strip">
                                
                                </div>
                                <h3 className="skeleton-shop_name"></h3>
                                <div className="rating">
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                </div>
                                <div className="type"></div>
                                <div className="skeleton-shop_desc1"></div>
                                <div className="skeleton-shop_desc2"></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="go_to">
                                <div><a class="skeleton-btn_1" href="#"></a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip_list">                        
                    <div className="row">
                        <div className="col-md-9">
                            <div className="desc">
                                <div className="skeleton-thumb_strip">
                                
                                </div>
                                <h3 className="skeleton-shop_name"></h3>
                                <div className="rating">
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                    <i className="icon_star"></i>
                                </div>
                                <div className="type"></div>
                                <div className="skeleton-shop_desc1"></div>
                                <div className="skeleton-shop_desc2"></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="go_to">
                                <div><a class="skeleton-btn_1" href="#"></a></div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           

        </div>
  );
};

export default OrderListSkeleton;