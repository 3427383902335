import React from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";

const HomeSlider = ({banner_list}) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
       
      };
      return (
        <div>
         
          <Slider {...settings}>
            { banner_list.map( (item,i) => {

                    return(
                        
                        <div key={i} >
                          {
                            item.shop_id ? 
                            <Link to={`/shop/${item.shop_id}`}>
                              <img src={item.image}  style={{width:'100%',height:'450px'}}/>
                            </Link>
                            : 
                            <img src={item.image}  style={{width:'100%',height:'450px'}}/>
                          }
                          
                        </div>  

                )
                })
            }
          </Slider>
          </div>
        
      );

}

export default HomeSlider;