var url = window.location.href;
var urlData = url.split("/");
var domain = urlData[2];

if(domain == 'www.lavenderlogistic.com'){
    domain = "lavenderlogistic.com";
}

export const LIVE_DOMAIN = domain == 'localhost:3000' ?  'http://localhost/server-b2b/backend/' : domain == 'test.lavenderlogistic.com' ? 'https://test.lavenderlogistic.com/backend/' : 'https://lavenderlogistic.com/backend/';
const SOCEKT_PATH = domain == 'lavenderlogistic.com' ? 'wss://lavenderlogistic.com/wss2' : 'wss://test.lavenderlogistic.com/wss2';
const AWS_PATH = domain == 'lavenderlogistic.com' ? 'https://dfulmqtugkhua.cloudfront.net/' : 'https://dfulmqtugkhua.cloudfront.net/';

const API_VERSION = 'v4/';
const API_ENDPOINT = LIVE_DOMAIN + "api/" + API_VERSION;

export const HOME_API_URL = API_ENDPOINT + 'orders/homeShopList';
export const SHOP_DETAIL_API_URL = API_ENDPOINT + 'orders/getProductList';
export const PRODUCT_DETAIL_API_URL = API_ENDPOINT + 'orders/shopProductDetail';
export const SHOP_EXISTS_API_URL = API_ENDPOINT + 'orders/shopExits';
export const SHOP_DELIVERED_OR_NOT_API_URL = API_ENDPOINT + 'orders/shop_delivered_or_not';
export const SAVE_ADDRESS = API_ENDPOINT + 'orders/save_address';
export const VIEW_CART_API_URL = API_ENDPOINT + 'orders/viewCartItem';
export const ADD_TO_CART_API_URL = API_ENDPOINT + 'orders/addToCartItem';
export const ORDER_PLACE_URL = API_ENDPOINT + 'orders/placeOrder';
export const REMOVE_CART_ITEM = API_ENDPOINT + 'orders/removeCartItem';
export const GET_PAYMENT_METHOD_API = API_ENDPOINT + 'orders/get_payment_list';
export const ORDER_STAMP_UPLOAD = API_ENDPOINT + 'orders/stamp_image_upload';
export const ORDER_LOCATION_CHANGE = API_ENDPOINT + 'orders/change_gift_order_address';

export const ORDER_LISTING = API_ENDPOINT + 'customer_order/listing';
export const ORDER_DETAIL = API_ENDPOINT + 'customer_order/detail';
export const CREATE_CUSTOMER = API_ENDPOINT + 'customer_order/web_create_customer';
export const CUSTOMER_ORDER_CANCEL = API_ENDPOINT + 'customer_order/canceled';
export const CUSTOMER_ORDER_RECEIVED = API_ENDPOINT + 'customer_order/order_received';
export const ORDER_COMPLAIN_API = API_ENDPOINT + 'customer_order/customer_complaint';
export const CUSTOMER_CC_ADD_API = API_ENDPOINT + 'customer_order/add_customer_cc_details';
export const SEND_OTP_API = API_ENDPOINT + 'customer_order/sendOtp';
export const VERIFY_OTP_API = API_ENDPOINT + 'customer_order/verifyOtp';
export const CUSTMER_VERIFY = API_ENDPOINT + 'customer_order/checkCustomerVerify';
export const CUSTMER_EXISTS = API_ENDPOINT + 'customer_order/customer_exists';
export const ADD_LOCATION_FROM_SAVE_LIST = API_ENDPOINT + 'customer_order/add_location_from_save_list';
export const LOGIN_API = API_ENDPOINT + 'customer_order/login';
export const CUSTOMER_LOGOUT = API_ENDPOINT + 'customer_order/logout';
export const REMOVE_ADDRESS_FROM_SAVE = API_ENDPOINT + 'customer_order/delete_customer_address';

export const GET_PAYMENT_CHECKOUT_ID_API = API_ENDPOINT + 'payment/getPaymentCheckoutId';
export const GET_PAYMENT_STATUS_API = API_ENDPOINT + 'payment/getPaymentStatus';
export const ORDER_PAYMENT_UPDATE = API_ENDPOINT + 'payment/updateOrderPaymentData';

export const CUSTMER_CHAT = API_ENDPOINT + 'chat/customer_chat_history';

export const MAP_GOOGLE_KEY = "AIzaSyBdyEd6pVqNBEcyDJcfO4hf46QXJDPk3dM";
export const B2BSOCKET_URL = SOCEKT_PATH;
export const AWS_IMG_PATH = AWS_PATH;