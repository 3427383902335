import React from 'react';
import { NavLink, Link, withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import {AWS_IMG_PATH, CUSTOMER_LOGOUT} from '../Comman/Constant';


function logoutByCustomer () {
    localStorage.removeItem('customer_mobile');  
    localStorage.removeItem('device_id');  
    // const requestParam = {
    //     language_id: 1,        
    // }
    
    // axios.post(CUSTOMER_LOGOUT, requestParam)
    // .then((response) => {            
    //     if(response.data.success == true){
    //         localStorage.setItem('device_id', response.data.device_id);                            
    //     }
    // });
}

const Header = (props) => {
    const customerLat = localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "";
    const device_id = localStorage.getItem('device_id') ? localStorage.getItem('device_id') : "";    
    const customer_mobile = localStorage.getItem('customer_mobile') ? localStorage.getItem('customer_mobile') : "";
    const location_path = props.location.pathname;
    return(
        <div>
        {
            location_path != '/landing' ? 
             <div>
                <div id="preloader">
                    <div className="sk-spinner sk-spinner-wave" id="status">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                    </div>
                </div>
                <>            
                {
                    
                    props.location.pathname.indexOf('change_address') === 1 ?
                        <header style={{"padding":"0px"}} className="sticky">
                             <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 col-sm-4 col-4">
                                        <NavLink to='/' id="logo">
                                            <img height="50px" src={AWS_IMG_PATH + "extra_images/lavanderlogistic.png"} alt="" className="d-none d-sm-block" />
                                            <img height="50px" src={AWS_IMG_PATH + "extra_images/lavanderlogistic.png"} alt="" className="d-block d-sm-none" />
                                        </NavLink>
                                    </div>
                                    <nav style={{"paddingTop":"15px"}} className="col-md-8 col-sm-8 col-8">
                                    <a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" href="#"><span>Menu mobile</span></a>
                                    <div className="main-menu">
                                        <div id="header_menu">
                                            <img height="50px" src={AWS_IMG_PATH + "extra_images/lavanderlogistic.png"} alt="" />
                                        </div>
                                        <a href="#" className="open_close" id="close_in"><i className="icon_close"></i></a>
                                        <ul>
                                            <li className="submenu">
                                                {
                                                    device_id && <NavLink to='/'>Home</NavLink>
                                                }                            
                                            </li>

                                            <li>
                                                {
                                                    device_id && device_id && <NavLink to='/order_step_one'>Your Cart</NavLink> 
                                                }                  
                                            </li>
                                            
                                            <li>
                                                {
                                                device_id && <NavLink to='/order_list'>Order List</NavLink>
                                                }                  
                                            </li>    

                                            <li>
                                                {
                                                !customer_mobile && <NavLink to='/sign-in'>Login</NavLink> 
                                                }                  
                                            </li> 

                                            <li>
                                                {
                                                    device_id && <NavLink to='/your_location'>Change Address</NavLink>                         
                                                }     
                                            </li> 

                                            <li>
                                                {
                                                    customer_mobile && <NavLink to='/sign-in' onClick={logoutByCustomer}>Logout</NavLink>                         
                                                }     
                                            </li>
                                        </ul>
                                    </div>
                                    </nav>
                                </div>
                            </div>
                        </header>
                    :
                    <header style={{"padding":"0px"}} className={ props.location.pathname == '/your_location' || props.location.pathname == '/login' || props.location.pathname == '/sign-in' || props.location.pathname == '/sign-up' || props.location.pathname == '/add_location' || props.location.pathname == '/landing' ? "sticky" : "" }  >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <NavLink to='/' id="logo">
                                            <img height="50px" src={AWS_IMG_PATH + "extra_images/lavanderlogistic.png"} alt="" className="d-none d-sm-block" />
                                            <img height="50px" src={AWS_IMG_PATH + "extra_images/lavanderlogistic.png"} alt="" className="d-block d-sm-none" />
                                        </NavLink>
                                </div>
                                <nav style={{"paddingTop":"15px"}} className="col-md-8 col-sm-8 col-8">
                                <a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" href="#"><span>Menu mobile</span></a>
                                <div className="main-menu">
                                    <div id="header_menu">
                                        <img height="50px" src={AWS_IMG_PATH + "extra_images/lavanderlogistic.png"} alt="" />
                                    </div>
                                    <a href="#" className="open_close" id="close_in"><i className="icon_close"></i></a>
                                    <ul>
                                        <li className="submenu">
                                            {
                                                device_id && <NavLink to='/'>Home</NavLink>
                                            }                            
                                        </li>

                                        <li>
                                            {
                                                device_id && device_id && <NavLink to='/order_step_one'>Your Cart</NavLink> 
                                            }                  
                                        </li>
                                        
                                        <li>
                                            {
                                            device_id && <NavLink to='/order_list'>Order List</NavLink>
                                            }                  
                                        </li>    

                                        <li>
                                            {
                                            !customer_mobile && <NavLink to='/sign-in'>Login</NavLink> 
                                            }                  
                                        </li> 

                                        <li>
                                            {
                                                device_id && <NavLink to='/your_location'>Change Address</NavLink>                         
                                            }     
                                        </li> 

                                        <li>
                                            {
                                                customer_mobile && <NavLink to='/sign-in' onClick={logoutByCustomer}>Logout</NavLink>                         
                                            }     
                                        </li>
                                    </ul>
                                </div>
                                </nav>
                            </div>
                        </div>
                    </header>
                }

                </>
            </div>
            : null
        }
        </div>
    )

}

export default withRouter(Header);