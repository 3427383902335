import "./Skeleton.css";

import React from "react";
import Slider from "react-slick";

const HomePageSkeleton = () => {
    const settings = {
        arrows: false,
        autoplay: false,        
        rows: 1,
        slidesToShow: 12,
        slidesToScroll: 12,
        infinite: false,         
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ]
  };
  return (
        <div className="skeleton-homeContainer">
            <div className="skeleton-slider">

            </div>

            <Slider {...settings}>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
                <div className="skeleton-cat-img-block"></div>
            </Slider >

            <div className="container-fluid full-height mt-3">
                <div className="row row-height">
                    <div className="col-lg-12 content-left" >

                        <div className="strip_list">                        
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="desc">
                                        <div className="skeleton-thumb_strip">
                                        
                                        </div>
                                        <h3 className="skeleton-shop_name"></h3>
                                        <div className="rating">
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                        </div>
                                        <div className="type"></div>
                                        <div className="skeleton-shop_desc1"></div>
                                        <div className="skeleton-shop_desc2"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="go_to">
                                        <div><a className="skeleton-btn_1" href="#"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="strip_list">                        
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="desc">
                                        <div className="skeleton-thumb_strip">
                                        
                                        </div>
                                        <h3 className="skeleton-shop_name"></h3>
                                        <div className="rating">
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                        </div>
                                        <div className="type"></div>
                                        <div className="skeleton-shop_desc1"></div>
                                        <div className="skeleton-shop_desc2"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="go_to">
                                        <div><a className="skeleton-btn_1" href="#"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="strip_list">                        
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="desc">
                                        <div className="skeleton-thumb_strip">
                                        
                                        </div>
                                        <h3 className="skeleton-shop_name"></h3>
                                        <div className="rating">
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                        </div>
                                        <div className="type"></div>
                                        <div className="skeleton-shop_desc1"></div>
                                        <div className="skeleton-shop_desc2"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="go_to">
                                        <div><a className="skeleton-btn_1" href="#"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="strip_list">                        
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="desc">
                                        <div className="skeleton-thumb_strip">
                                        
                                        </div>
                                        <h3 className="skeleton-shop_name"></h3>
                                        <div className="rating">
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                        </div>
                                        <div className="type"></div>
                                        <div className="skeleton-shop_desc1"></div>
                                        <div className="skeleton-shop_desc2"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="go_to">
                                        <div><a className="skeleton-btn_1" href="#"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="strip_list">                        
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="desc">
                                        <div className="skeleton-thumb_strip">
                                        
                                        </div>
                                        <h3 className="skeleton-shop_name"></h3>
                                        <div className="rating">
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                            <i className="icon_star"></i>
                                        </div>
                                        <div className="type"></div>
                                        <div className="skeleton-shop_desc1"></div>
                                        <div className="skeleton-shop_desc2"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="go_to">
                                        <div><a className="skeleton-btn_1" href="#"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    {/* <div className="col-lg-5 skeleton-map-right"> 
                    </div> */}
                </div>
            </div>
            
        </div>
  );
};

export default HomePageSkeleton;