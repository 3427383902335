import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { CUSTMER_EXISTS, SEND_OTP_API, VERIFY_OTP_API, } from '../Comman/Constant';

class Signup extends React.Component{

    constructor(props){
        super(props);
      
        this.state = {                  
            mobileCode : "+966",
            screen: "mobile",
            customer_mobile: "",
            otp: new Array(4).fill(""),
            redirect_to: new URLSearchParams(this.props.location.search).get("redirect_to"),
            isLoading: false,
            sub_msg: "",
            success_msg: "",
          };   
            
    }

    onChageHandle = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        const name_error = name + "_error";


        if( name == 'customer_name'){
          value = value.replace(/[^A-Za-z ]/ig, '')
        }

        if( name == 'customer_mobile'){
          if(isNaN(value))  return false;
        }
       
        this.setState(
            {
                [event.target.name]: value,
                [name_error]: ""
            }
            
        );
    }

    customer_register = () => {

        this.setState({
          isLoading: true,    
        });

        const customer_name_error = !this.state.customer_name ? "Please enter your name." : "";
        let customer_email_error = !this.state.customer_email ? "Please enter your email." : "";
        let customer_mobile_error = !this.state.customer_mobile ? "Please enter your mobile number." : "";
         
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.customer_email)) {
            customer_email_error = "Please enter valid email address."
        }

        if(!customer_mobile_error){
            if(this.state.customer_mobile.length < 9){
                 customer_mobile_error = "Mobile number should be 9 digit."
            }
        }

        let password_error = "";
        if(this.state.password){
            if(this.state.password.length < 8){
                 password_error = "Password must be min 8 chararacters."
            }
            if(this.state.password.length > 15){
                 password_error = "Password must be max 15 chararacters."
            }
        } else {
            password_error = "Please enter your password."
        }

        let repassword_error = "";
        if(this.state.repassword){
            if(this.state.password != this.state.repassword){
                repassword_error = "Your enterd re-passowrd did not match."
            }
        } else {
            repassword_error = "Please re-enter your password."
        }
        

        if(customer_name_error || customer_email_error || customer_mobile_error || password_error || repassword_error){

        } else {
           const requestParam = {
                language_id: 1,
                customer_email: this.state.customer_email
            }
            this.checkCustomerExits(requestParam);
        }

        this.setState({
            customer_name_error : customer_name_error,
            customer_email_error : customer_email_error,
            customer_mobile_error: customer_mobile_error,           
            password_error: password_error,
            repassword_error: repassword_error,
            isLoading: false,    
        });

    }

    checkCustomerExits = (requestParam) => {

        this.setState({
          isLoading: true,    
        });

        axios.get(CUSTMER_EXISTS, {
            params: requestParam        
        })
        .then((response) => {
            if(response.data.exists == 1){
                this.setState({
                customer_email_error: "This email address already exist, please try with diffrent email.",
                isLoading: false, 
                });
            } else {            
                this.sendOtp();  
            }                  
        });
    }

    change_number = () =>
    {
        this.setState({
          screen: "mobile",
          error: "",
           otp: new Array(4).fill(""),
        });
    }

    resend_otp = () =>
    {
        this.sendOtp();

        confirmAlert({
          title: '',
          message:"OTP resent successfully",
          buttons: [
            {
              label: 'Okay',                        
            },
            
          ]
        });

    }

    sendOtp = () => {

        const requestParam = {
            language_id: 1,        
            customer_mobile: this.state.customer_mobile,
            customer_email: this.state.customer_email,
            customer_name: this.state.customer_name,
        }
        this.setState({
            screen: "otp",  
            isLoading: false,  
        });
        axios.post(SEND_OTP_API, requestParam)
         .then((response) => {            
         
        });

    }

    verify_otp = () => {

        this.setState({
          isLoading: true,    
        });
        const device_id = localStorage.getItem('device_id');
        const requestParam = {
            language_id: 1,    
            otp: this.state.otp.join(""),
            customer_mobile: this.state.mobileCode + this.state.customer_mobile,
            customer_name: this.state.customer_name,
            customer_email: this.state.customer_email,
            customer_mobile: this.state.mobileCode+this.state.customer_mobile,
            password: this.state.password,
        }
          axios.post(VERIFY_OTP_API, requestParam)
            .then((response) => {            
              if(response.data.success == true){
                   //localStorage.setItem('device_id', response.data.device_id);                
                  this.setState({
                   screen:"thankyou",
                   isLoading: false,    
                   sub_msg: response.data.sub_msg,
                   success_msg: response.data.success_msg,
                  })
                
              } else {
                  this.setState({
                    error: "Wrong verification code",
                    otp: new Array(4).fill(""),
                    isLoading: false,
                  })
              }
          });
    }

    changeOtpHandle = (element, index) => {

          if(isNaN(element.value))  return false;
  
          this.state.otp[element.id] = element.value;
        
          this.setState({ 
              otp : this.state.otp,
              error: "",
          });


          if(element.previousSibling && !element.value){
              element.previousSibling.focus();
          }
          
          if(element.nextSibling && element.value){
              element.nextSibling.focus();
          }

    }

    showPassword = () => {
        $(this).toggleClass("fa-eye fa-eye-slash");

        var passwordElement = document.getElementById("password");
        var passwordShow = document.getElementById("password_show");
    
        if (passwordElement.type === "password") {
            passwordElement.type = "text";
            passwordShow.classList.remove('fa-eye');
            passwordShow.classList.add('fa-eye-slash');
        } else {
            passwordElement.type = "password";
            passwordShow.classList.remove('fa-eye-slash');
            passwordShow.classList.add('fa-eye');
        }
    }

    render(){
        const isLoading =  this.state.isLoading;
    
        return(
            <>  
            {
                <div className="row login_rw_scn">
                    <div className="col-lg-4"></div>

                    {
                         this.state.screen == "mobile" && 

                         <div className="col-lg-4">
                            <div className="d-flex justify-content-center align-items-center container margin_60_35 login_container">
                                <div className="card py-5 px-3">
                                    <div class="login_icon" style={{color:"#5A368D",textAlign:"center"}}><i class="icon_lock_alt"></i></div>                                                                                      
                                    <div class="wrapper_indent">
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Full Name</label>
                                                    <input type="text" id="full_name" name="customer_name" class="form-control" onChange={this.onChageHandle} value={this.state.customer_name}/>
                                                    <span className="text-danger">{this.state.customer_name_error}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>E-mail</label>
                                                    <input type="email" id="customer_email" name="customer_email" class="form-control" onChange={this.onChageHandle} value={this.state.customer_email}/>
                                                    <span className="text-danger">{this.state.customer_email_error}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Mobile Number</label>
                                                    <div className="row">
                                                        <div class="col-sm-3 col-lg-3">
                                                            <select className="form-control">
                                                                <option>+966</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-9 col-lg-9">
                                                            <input type="text" className="form-control" name="customer_mobile" maxLength="10" placeholder="Mobile Number" autoFocus="" onChange={this.onChageHandle} value={this.state.customer_mobile}/>
                                                            <span className="text-danger">{this.state.customer_mobile_error}</span>
                                                        </div>
                                                    </div>                                                                                            
                                                </div>
                                            </div>
                                        
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Password</label>
                                                    <input type="password" id="password" name="password" class="form-control" onChange={this.onChageHandle} value={this.state.password}/>
                                                    <span toggle="#password-field" id="password_show" class="fa fa-fw fa-eye field-icon toggle-password" onClick={this.showPassword}></span>
                                                    <span className="text-danger">{this.state.password_error}</span>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Confirm Password</label>
                                                    <input type="password" id="re-password" name="repassword" class="form-control" onChange={this.onChageHandle} value={this.state.repassword}/>
                                                    <span className="text-danger">{this.state.repassword_error}</span>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                     {
                                                        isLoading == false ? <input type="button" className="btn_full deliver_here_btn" value="CREATE YOUR ACCOUNT" onClick={this.customer_register}></input>
                                                        :  <a className="btn_full1 skeleton-btn" href=""></a>
                                                    }
                                                
                                                </div>
                                            </div>                                    
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4 col-lg-4">
                                                
                                            </div>
                                            <div class="col-sm-8 col-lg-8 text-right">
                                                <Link to="/sign-in"><strong>Already have an account? Login here.</strong></Link>
                                            </div>
                                        </div>
                                    
                                    </div>                
                                </div>     
                            </div>
                        </div>
                    }
                    
                    {
                         this.state.screen == "otp" && 
                         <div className="col-lg-4">
                            <div className="d-flex justify-content-center align-items-center container margin_60_35 otp_container">
                
                                <div className="card py-5 px-3 otp_container">
                                    <h3>Verify your phone number with PIN</h3>                            
                                    <span className="mobile-text text-secondary">Enter the code we just send on your email address <b class="text-danger">{this.state.customer_email}</b></span>                            
                                
                                    {
                                        this.state.error && 
                                        <div className="mt-3">
                                            <span className="p-2 text-light rounded-10 bg-danger" style={{borderRadius: "5px"}}>{this.state.error}</span>
                                        </div>
                                    }
                                

                                    <div className="d-flex flex-row mt-4" style={{textAlign: "center", padding: "0px 90px"}}>

                                    {
                                        this.state.otp.map((otpData, otpKey) => {
                                            return <input type="text" autoFocus={otpKey == 0 ? "true":""}  name="otp" key={otpKey} id={otpKey} value={otpData} className="form-control" maxLength="1" onChange={e => this.changeOtpHandle(e.target, otpKey)} onFocus={e => e.target.select()} onKeyDown={this.keyDownHandle}/>
                                        })
                                    }
                        
                                    </div>
                                    <div className="text-center mt-5">
                                        <span className="d-block mobile-text">Don't receive the code?</span>
                                        <span className="font-weight-bold text-danger cursor" style={{cursor: "pointer"}} onClick={this.change_number}>Change Number </span> | 
                                        <span className="font-weight-bold text-danger cursor" style={{cursor: "pointer"}} onClick={this.resend_otp}> Resend </span>
                                        
                                        {
                                            isLoading == false ? <input type="button" className="btn_full deliver_here_btn" value="VERIFY" onClick={this.verify_otp}></input> 
                                            :  <a className="btn_full1 skeleton-btn" href=""></a>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    }  

                    {

                        this.state.screen == 'thankyou' &&
                        <div className="col-lg-4 margin_60_35">
                            <div className="box_style_2">
                               
                                <div id="confirm">
                                    <i className="icon_check_alt2"></i>
                                    <h3>{this.state.success_msg}</h3>
                                    <p className="text-danger">{this.state.sub_msg}</p>
                                </div>
                            </div>
                        </div>

                    }              
                  <div className="col-lg-4"></div>
                </div>
            }
            
            
            </>
          );
    }

}

export default Signup;