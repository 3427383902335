import React, { Component } from 'react';
import axios from "axios";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Autocomplete from "react-google-autocomplete";

import { SHOP_EXISTS_API_URL, ORDER_LOCATION_CHANGE, MAP_GOOGLE_KEY, B2BSOCKET_URL } from '../Comman/Constant';

class  OrderLocationSelector extends React.Component{

    constructor(props){
        super(props);

        const order_id = this.props.match.params.id;
        this.state = {                  
            customerAddress : "",
            order_id: order_id
          };         
    }


    componentDidMount(){
      this.conn = new WebSocket(B2BSOCKET_URL);
       this.setState({
        customerLat: '24.713749728746304',
        customerLng: '46.672418121606455',  
        customerLatReset: '24.713749728746304',
        customerLngReset: '46.672418121606455', 
        customerAddressReset: 'PM7C+GW5, Al Wurud, Riyadh 12215, Saudi Arabia', 
        customerAddress: 'PM7C+GW5, Al Wurud, Riyadh 12215, Saudi Arabia',              
      });
               
      localStorage.setItem('customerLatSelection','24.713749728746304');
      localStorage.setItem('customerLongSelection', '46.672418121606455');
      
    }

    resetAddress = () => {
      this.setState({
            customerLat: this.state.customerLatReset,
            customerLng: this.state.customerLngReset,            
            customerAddress: this.state.customerAddressReset,              
          });
    }

    deliver_here = () => {
      var that = this;

        axios.post(ORDER_LOCATION_CHANGE, {
            language_id: 1,
            shop_latitude: this.state.customerLat,
            shop_longitude: this.state.customerLng,     
            location: this.state.customerAddress,
            order_id: this.state.order_id          
        })
        .then((response) => {
          if(response.data.is_return == 'no' || response.data.error){
            confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='react-confirm-alert-body'>
                      <p className="text-danger cant_delivery_here">{ response.data.alert_msg ? response.data.alert_msg : response.data.error}</p>
					            <div className="react-confirm-alert-button-group">
                      <button onClick={onClose}>Okay</button>
                      
                      </div>                  
                    </div>
                  );
                }
              });
          } else {
            const socketData = {            
                order_id: this.state.order_id,               
                requesttype:"order_location",              
                device_type:"web",
                source_type:"b2b",                              
                new_location:this.state.customerAddress,                              
            }
            that.conn.send(JSON.stringify(socketData));

            confirmAlert({
                customUI: (onClose) => {
                  return (
                    <div className='react-confirm-alert-body'>
                      <p className="text-danger cant_delivery_here">Your address set successfully</p>
					            <div className="react-confirm-alert-button-group">
                      <button
                      onClick={() => {
                        window.location.href = '/'; //Will take you to Google.                 
                      }}>Okay</button>                      
                      </div>                  
                    </div>
                  );
                }
              });
          }
        }).catch(function (error) {               
              if(error.response.status==401){
                    that.props.history.push('/sign-in');
              }
        });
    }


    onMarkerDragEnd = (coord, from_action = '') => {

        const { latLng } = coord;
              
        const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const google = window.google
        let geocoder = new google.maps.Geocoder();
        let customerAddress = '';

        geocoder.geocode({
          latLng: latLng
        }, function(responses) {
          if (responses && responses.length > 0) {            
              customerAddress = responses[0].formatted_address;
              this.setState({
                customerLat: currentLatitude,
                customerLng: currentLongitude,  
                customerAddress: customerAddress,
              });                         
          }
        }.bind(this));
    };

    mapClicked = (mapProps, map, clickEvent) => {
    
     const latLng  = clickEvent.latLng;
  
      const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const google = window.google
        let geocoder = new google.maps.Geocoder();
        let customerAddress = '';

        geocoder.geocode({
          latLng: latLng
        }, function(responses) {
          if (responses && responses.length > 0) {            
              customerAddress = responses[0].formatted_address;
              this.setState({
                customerLat: currentLatitude,
                customerLng: currentLongitude,  
                customerAddress: customerAddress,
              });
                         
          }
        }.bind(this));

        

    }   

    onPlaceSelected = (place) => {
        let customerAddress = place.formatted_address;
          const currentLatitude = place.geometry.location.lat();
          const currentLongitude = place.geometry.location.lng();
      
          this.setState({
            customerLat: currentLatitude,
            customerLng: currentLongitude,  
            customerAddress: customerAddress,
          });              
    }

    render(){
        const mapStyles = {
            width: '100%',
            height: '500px',
          };

          console.log(this.state);
      
          return(
            <>          
            {
              this.state.customerLat && 
              <div id="customer_location_map">
                <div className='autocomplete_div'>
                  <Autocomplete
                      apiKey={MAP_GOOGLE_KEY}
                      onPlaceSelected={(place) => this.onPlaceSelected(place)}
                      options={{
                          types: ["geocode", "establishment"],
                      }}
                  />
                  <div className="row">
                      <div className="col-lg-12"><input type="button" className="btn_full1 order_loction_confirm_btn deliver_here_btn" value="تاكيد الموقع  confirm location" onClick={this.deliver_here}></input></div>                               
                  </div>
                </div>
              <Map
              key={new Date().getTime()}
              google={this.props.google}
              zoom={14}
              style={mapStyles}
              initialCenter={{ lat: this.state.customerLat, lng: this.state.customerLng}}
              onClick={this.mapClicked}
              >
                <Marker
                position={{ lat: this.state.customerLat, lng: this.state.customerLng}}
                draggable={true}
                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}      
                />
              </Map>
            </div>
            }
            
            <div className="customerLocationInfo container margin_60_35">
              {
                this.state.currentErrorCode == 1 &&
                <div className="row">
                    <div className="col-lg-6 choosenLocation">
                        <span className="font-weight-bold">Error:</span>
                        <span className="customerSelectedAddress">Please allow location permission</span>
                    </div>
                </div>
              }
              {
                this.state.customerLat && 
                <div className="row">
                    <div className="col-lg-12 choosenLocation">
                        <span className="font-weight-bold">Deliver to:</span>
                        <span className="customerSelectedAddress">{this.state.customerAddress}</span>
                    </div>                
                </div>
              }
                
            </div>
        </>
          );
    }

}

export default GoogleApiWrapper({
  apiKey: MAP_GOOGLE_KEY
})(OrderLocationSelector);