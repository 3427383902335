import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {LIVE_DOMAIN} from '../Comman/Constant';
class OrderListItem extends Component {

    constructor(props){
        super(props);

        this.state = {    
            order_id: this.props.id,
            order_data: this.props.order_data,   
            order_status: this.props.order_data.status,    
            customer_cancel: this.props.order_data.customer_cancel,           
            product_ds_or_not: this.props.order_data.product_ds_or_not,
            
        }
    }

    render(){
    
        const orderData = this.state.order_data;
        return(
            <>
                 <div className="strip_list">
                   
                    <div className="ribbon ribbon-top-left">
                        <span id={"order_status_"+orderData.id} style={this.state.customer_cancel == 'yes' ? {backgroundColor:"#ff2222"} : null}>
                            { 
                                this.state.customer_cancel == 'yes' ? "CANCELLED" : 
                                this.state.product_ds_or_not =='yes' ? "DELIVERED" :  
                                this.state.order_status =='pending' ? "PENDING" : "CONFIREMD"
                            }
                        </span>
                        </div>
                    
                    <div className="row">
                        <div className="col-md-9">
                    
                            <div className="desc">
                                <div className="thumb_strip">
                                    <Link to={`/order_detail/${orderData.id}`}>
                                        <img src={orderData.image} alt="" />
                                    </Link> 
                                </div>                               
                                <h3>{orderData.shop_name}</h3>
                                 
                                <div className="type"></div>
                                <div>
                                    <strong>Order No:</strong> {orderData.id}
                                </div>
                                <div>
                                    <strong>Schedule Date:</strong> {orderData.schedule_date}
                                </div>
                               
                            </div>
                            </div>
                            <div className="col-md-3">
                                <div className="go_to">
                                    <div>
                                        <Link to={`/order_detail/${orderData.id}`} className="btn_1">View Order</Link>                                    
                                    </div>
                                    <div>
                                        <a target='_blank' href={`${LIVE_DOMAIN}/download_invoice/download_pdf/${orderData.id}`} className="btn_1">Invoice</a>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> 
            </>
        )
    }
}

export default OrderListItem;