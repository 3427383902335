import React, {Component} from 'react';
import { Marker, InfoWindow } from "react-google-maps";
import { Link } from "react-router-dom";

class SingleMarkerMap extends Component {

    constructor(props){
        super(props);
      
        this.state = {
            isOpen: false,
            marker_data : this.props.marks,
            index : this.props.index
        }
    }

    handleToggleOpen(keyIndex){
       
        this.setState({
            isOpen: keyIndex
        });
    }

    handleToggleClose(keyIndex) {
        this.setState({
            isOpen: keyIndex
        });
    }


  render() {
    const markerData = this.state.marker_data;
    return (
        <>
        {
            markerData.map((mark, keyIndex) => (
                <Marker
                    key={keyIndex}
                    position={{ lat: parseFloat(mark.shop_latitude), lng: parseFloat(mark.shop_longitude) }}
                    icon={{
                    url:  "https://d7q1bcuj8cly9.cloudfront.net/extra_images/icon21.png",            
                    }}
                    onClick={()=>{ this.handleToggleOpen(keyIndex)}}
                
                >
                    {
                            this.state.isOpen === keyIndex &&
                            <InfoWindow onClick={()=>{ this.handleToggleClose(keyIndex)}}>
                                <div class="marker_info">
                                    <img src={mark.image} alt=""/>
                                    <h3>{mark.shop_name}</h3>                                
                                    <em>{mark.shop_category}</em>
                                    <span></span>
                                    <Link to={`/shop/${mark.shop_id}`} className="btn_1">Details</Link>
                                </div>
                            </InfoWindow>               
                    }
                </Marker>
            ))
        }
        </>
    )
  }
}

export default SingleMarkerMap;