import React, { Component } from 'react';
import axios from "axios";
import { Redirect,  Link   } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import CartSummeryWidegt from '../Widget/cartSummeryWidget';
import ViewCartSkeleton from '../skeleton/view_cart_skeleton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import {SHOP_DETAIL_API_URL,ADD_TO_CART_API_URL,REMOVE_CART_ITEM, AWS_IMG_PATH} from '../Comman/Constant';

class ShopDetail extends Component {
    constructor(props){
        super(props);    

        this.state = {
            productListData: [],     
            shop_data: [], 
            category_data: [],                  
            isCartLoading: false, 
            device_id : localStorage.getItem('device_id'),
            shop_id: this.props.match.params.id,
            price_show : "no"
        }

        this.fetchData = this.fetchData.bind(this); 
        //this.openDetail = this.openDetail.bind(this);             
    }

    componentDidMount(){    
        const requestParam = {
            language_id: 1,
            shop_id: this.state.shop_id,
            device_id: this.state.device_id,
        }
        this.fetchData(requestParam);
    }

    forceReRander = () =>{
        const requestParam = {
            language_id: 1,
            shop_id: this.props.match.params.id,
            device_id: this.state.device_id,
        }
        this.fetchData(requestParam);
    }

    openDetail = (e,product_code) => {
            e.preventDefault();
            localStorage.setItem('productCode',product_code); 
            this.props.history.push("/shop/"+this.state.shop_id+"/detail");
    }

    fetchData = (requestParam) => {
    var that = this;        
    axios.get(SHOP_DETAIL_API_URL, 
        {
            params: requestParam,  
            headers: {
            'Authorization': requestParam.device_id 
            }      
        }
    )
    .then((response) => {
        this.setState({
        productListData: response.data.items,  
        shop_data: response.data.shop_data,  
        category_data: response.data.category_data,
        price_show: response.data.price_show,   
        });        
    }).catch(function (error) {
               
        if(error.response.status==401){
                that.props.history.push('/sign-in');
        }
    });
    }

    directAddToCartWeight = (event,weight_id,product_code) => {
        event.preventDefault();    
        var value = weight_id;

        this.setState({
             isCartLoading: true,   
        });

        axios.post(ADD_TO_CART_API_URL, {
            
            language_id: 1,
            weight_data: [value],
            shop_id: this.props.match.params.id,
            city_id: this.state.shop_data.city_id,
            device_id: localStorage.getItem('device_id'),
            product_code:  product_code
        
        },{ 
        headers: {
        'Authorization': localStorage.getItem('device_id') 
        }
        }).then((response) => {     
            
            response.data.weight_data = [value];
            response.data.product_code = product_code;                
            //document.body.click()               
            this.renderResponse(response);
            
        })
        
    }

    directRemoveFromCartWeight = (event,cart_table_id,product_code) => {
        event.preventDefault();    
        axios.post(REMOVE_CART_ITEM, {                    
            language_id: 1,
            cart_table_id: cart_table_id,
            product_code: product_code,
            device_id: localStorage.getItem('device_id')
        
        },{ 
        headers: {
        'Authorization': localStorage.getItem('device_id') 
        }
        }).then((response) => {
            this.forceReRander();   
            this.setState({                
                unique_id: response.data.unique_id, 
                isCartLoading: false,                              
            });  
            
        });
    }

    addToCartWeight = (event) => {
        const target = event.target;
        var value = target.value;
        let product_code = event.currentTarget.getAttribute('data-hfgkkeirn4df');
        
        this.setState({
             isCartLoading: true,   
        });
       
        if(target.checked){
            axios.post(ADD_TO_CART_API_URL, {
            
                language_id: 1,
                weight_data: [value],
                shop_id: this.props.match.params.id,
                city_id: this.state.shop_data.city_id,
                device_id: localStorage.getItem('device_id'),
                product_code:  target.dataset.hfgkkeirn4df
            
            },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
            }).then((response) => {     
               
                response.data.weight_data = [value];
                response.data.product_code = product_code;                
                //document.body.click()               
                this.renderResponse(response);
              
            })
        }else{
            axios.post(REMOVE_CART_ITEM, {
            
                language_id: 1,
                cart_table_id: target.dataset.index,
                product_code: target.dataset.hfgkkeirn4df,
                device_id: localStorage.getItem('device_id')
            
            },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
            }).then((response) => {
                 this.forceReRander();   
                this.setState({                
                    unique_id: response.data.unique_id, 
                    isCartLoading: false,                              
                  });  
                 
            });
        }
    }

    addTocartOverwrite = (response) => {
        axios.post(ADD_TO_CART_API_URL, {
                
            language_id: 1,
            weight_data: response.data.weight_data,
            shop_id: this.props.match.params.id,
            city_id: this.state.shop_data.city_id,
            device_id: localStorage.getItem('device_id'),
            overwrite: 'yes',
            product_code: response.data.product_code,
        
        },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
            }).then((response) => {                
            this.renderResponse(response);
        })
    }

    renderResponse = (response) => {

        response.data.error && document.body.click() 
        
        response.data.error ? 
    
        response.data.overwrite == 'yes' ?  confirmAlert({
            title: '',
            message:response.data.error,
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.addTocartOverwrite(response)                        
            },
            {
                label: 'No',                        
            }
            
            ]
        }) : 
        confirmAlert({
            title: '',
            message:response.data.error,
            buttons: [
            {
                label: 'Okay',                        
            },
            
            ]
        })
        : this.setState({
            customer_cart_amount: response.data.customer_cart_amount,  
            unique_id: response.data.unique_id, 
            isCartLoading: false,   
            error: response.data.error ? response.data.error : ""                              
        });
        this.forceReRander();
        this.setState({
            isCartLoading: false,                
            });

    }

    addToCartAddon = (event) => {
    const target = event.target;
    var value = target.value;
    let product_code = event.currentTarget.getAttribute('data-hfgkkeirn4df');
    this.setState({
        isCartLoading: true,                
    });
    if(target.checked){
    
        axios.post(ADD_TO_CART_API_URL, {
            
            language_id: 1,
            addon_data: [value],
            shop_id: this.props.match.params.id,
            city_id: this.state.shop_data.city_id,
            device_id: localStorage.getItem('device_id'),
            product_code:  target.dataset.hfgkkeirn4df
        
        },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
        }).then((response) => {
            response.data.weight_data = [value];
            //document.body.click()               
            this.renderResponse(response);   
        });


    }else{
        axios.post(REMOVE_CART_ITEM, {
        
            language_id: 1,
            cart_table_id: target.dataset.index,
            product_code: target.dataset.hfgkkeirn4df,
            device_id: localStorage.getItem('device_id')
        
        },{ 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
        }).then((response) => {
             this.forceReRander();   
            this.setState({                
                unique_id: response.data.unique_id, 
                isCartLoading: false,                              
            });     
        });
    }
    
    }

    render(){
    
        const productListData = this.state.productListData;
        const shopData = this.state.shop_data;
        const categoryData = this.state.category_data;
        const selectedWeightData = this.state.selected_weight_data;
        
        return(
            <div>
               
                <section className="parallax-window" id="short" style={{backgroundImage: `url(${AWS_IMG_PATH + "extra_images/shop_detail_default_banner.png"})`}}>
                    <div id="subheader">
                        <div id="sub_content">
                            <div id="thumb">
                                <img src={shopData.shop_photo} alt="" />
                            </div>
                            <h1>{shopData.shop_name}</h1>                                                 
                            {/* <div><i className="icon_pin"></i>{shopData.location}</div> */}
                        </div>                
                    </div>
                </section>

                <div className="container-fluid full-height mt-3">
                    <div className="row">
                        <div className="col-lg-2">
                        
                            <div className="box_style_1">
                                <ul id="cat_nav">
                                {
                                    categoryData.map( (catData,categoryKey) => {
                                        const catDisplay = catData.display;                                    
                                        return(
                                            <div key={categoryKey}>
                                            {
                                                catDisplay == true ? 
                                                    <li>
                                                        <HashLink
                                                        to={`/shop/${this.props.match.params.id}/#${catData.id}`}
                                                        >          
                                                        {catData.cat_name} <span>({catData.product_list.length})</span>
                                                        </HashLink>  
                                                    </li>
                                                 : null }
                                            </div>
                                        )
                                    })
                                }
                     
                                </ul>
                            </div>
        
                        </div>
                        <div className="col-lg-6">
                            <div className="box_style_2" id="main_menu">
                                <h2 className="inner">Menu</h2>
                                { 
                                    categoryData.map( (catData,categoryKey) => {
                                        const catDisplay = catData.display;
                                        
                                        return(
                                        
                                            <div key={categoryKey}>
                                                {catDisplay == true ? 
                                                <div id={catData.id}>
                                                   
                                                    <h3 className="nomargin_top" id="starters">{catData.cat_name}</h3>
                                                  
                                                    <table className="table table-striped cart-list">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Item
                                                                </th>
                                                                {/* <th>
                                                                    Price
                                                                </th> */}
                                                                <th>
                                                                    Order
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            { 
                                                                catData.product_list.map( (productData,productKey) => {                                                              
                                                                    const productWeight = productData.sizes;   
                                                                    const productAddon = productData.cousine_item;
                                                                    return(                                
                                                                        <tr key={productKey}>
                                                                            <td style={{width:"70%"}}>
                                                                                <figure className="thumb_menu_list product_list_image"><img src={productData.picture} alt="thumb" /></figure>
                                                                                <h4 style={{"fontWeight":"600"}}>{productData.name}</h4>
                                                                                <p className="product_description_label">
                                                                                {productData.description}
                                                                                <br /><strong className="text-danger">
                                                                                {
                                                                                    productData.product_note ?  "Product Note: " + productData.product_note   : null
                                                                                }
                                                                                </strong>
                                                                                </p>
                                                                               
                                                                            </td>
                                                                            {/* <td style={{width:"15%"}}>
                                                                                <strong>SAR {productData.unit_price}</strong>
                                                                            </td> */}
                                                                            {/* <td className="options" style={{width:"15%"}}>
                                                                                <div className="dropdown dropdown-options">
                                                                                    <a href="#" className="btn_full btn-add-to-cart" aria-expanded="true" onClick={(e) => { this.openDetail(e, productData.product_code)} }>VIEW</a>                                                                                  
                                                                            </div>
                                                                            </td> */}
                                                                            <td className="options" style={{width:"15%"}}>
                                                                                <div className="dropdown dropdown-options cart_drpdwn_btn">
                                                                                    {
                                                                                        productWeight.length == 1 ? 
                                                                                        
                                                                                            productWeight[0].is_select == 'yes' ?
                                                                                                <a href="#" className="dropdown-toggle btn_full btn-add-to-cart" onClick={e => this.directRemoveFromCartWeight(e,productWeight[0].id,productWeight[0].p_code) }>REMOVE FROM CART</a>                                                                                                                                                                                            
                                                                                            :
                                                                                            <a href="#" className="dropdown-toggle btn_full btn-add-to-cart" onClick={e => this.directAddToCartWeight(e,productWeight[0].id,productWeight[0].p_code)}>ADD TO CART</a>
                                                                                        :
                                                                                            <>
                                                                                            <a href="#" className="dropdown-toggle btn_full btn-add-to-cart" data-toggle="dropdown" aria-expanded="true">ADD TO CART</a>
                                                                                            <div className="dropdown-menu">
                                                                                                <h5>{productData.product_weight_label}</h5>
                                                                                                {                                                                                
                                                                                                    productWeight ? 
                                                                                                    productWeight.map( (weightData,productKey) => {
                                                                                                        return(
                                                                                                            <label key={productKey}>
                                                                                                                <input data-index={weightData.id} data-hfgkkeirn4df={weightData.p_code} type="checkbox" value={weightData.id} name="weight_data" onChange={this.addToCartWeight} checked={weightData.is_select =='yes' ? "checked" : ""}/>{weightData.weight} { this.state.price_show == 'yes' && <span>+ SAR{weightData.weight_price}</span>  }
                                                                                                            </label>
                                                                                                        ); 
                                                                                                    
                                                                                                    })
                                                                                                    : null                                                                                    
                                                                                                }

                                                                                       
                                                                                                {                                                                                
                                                                                                    productAddon ?    
                                                                                                    <>
                                                                                                        <h5>{productData.product_addon_label}</h5>
                                                                                                        {
                                                                                                            productAddon.map( (addonData,addonKey) => {
                                                                                                                return(
                                                                                                            <label key={addonKey}>
                                                                                                                <input data-index={addonData.id} data-hfgkkeirn4df={addonData.pcode} type="checkbox" value={addonData.id} name="addon_data" onChange={this.addToCartAddon} checked={addonData.is_select =='yes' ? "checked" : ""}/>{addonData.addon} { this.state.price_show == 'yes' && <span>+ SAR{addonData.price}</span> }
                                                                                                            </label>
                                                                                                                ); 
                                                                                                            
                                                                                                            })
                                                                                                        }
                                                                                                    </>                                                                                     
                                                                                           
                                                                                                    : null                                                                                
                                                                                                }
                                                                                                                                                                                                                                            
                                                                                            </div>
                                                                                            </>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                        </td>
                                                                    </tr>
                                                                    )
                                                                })
                                                            }                            
                                                        </tbody>
                                                    </table>
                                                    <hr />
                                                </div>
                                                : null }
                                            </div>    
                                        )
                                    })
                                }           
                            </div>                        
                        </div>
                         <div className="col-lg-4" id="sidebar">
                            {
                                this.state.isCartLoading == false ? 
                                    <CartSummeryWidegt key={this.state.unique_id} location={this.props.location} rerenderParentCallback={this.forceReRander}/>
                                :                             
                                    <ViewCartSkeleton key={this.state.unique_id} location={this.props.location}/>                        
                            }
                                           
                        </div>
                    </div>                
                </div>
        
            </div>
        )
    }
}

export default ShopDetail;