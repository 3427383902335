import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderListItem from './singleOrderRow';
import { HashLink } from 'react-router-hash-link';
import axios from "axios";

import {ORDER_LISTING, B2BSOCKET_URL, AWS_IMG_PATH} from '../Comman/Constant';
import OrderListSkeleton from '../skeleton/OrderListSkeleton';

class OrderList extends Component {

    constructor(props){
        super(props);
       
        const device_id = localStorage.getItem('device_id');
        this.state = {    
            order_list: [], 
            isLoading: true,  
            device_id: device_id,
            pager: {},  
            page: 1                 
        }
    }

    componentDidMount(){

       
        //this.b2bconnOrderList = new WebSocket(B2BSOCKET_URL);
        //this.b2bconnOrderList.onmessage = this.onMessage.bind(this);
        
        this.fetchData({});
    }

    fetchData = (requestParam) => {
        var that = this;

        requestParam.language_id = 1;
        requestParam.device_id = this.state.device_id;
        requestParam.page = this.state.page;

        axios.post(ORDER_LISTING,requestParam,
        { 
            headers: {
            'Authorization': requestParam.device_id 
            }
        }).then((response) => {
            this.setState({
                order_list: response.data.list ? response.data.list : [],    
                unique_id: response.data.unique_id,
                isLoading: false,    
                pager: response.data.pager,      
            });        
        }).catch(function (error) {
               
                if(error.response.status==401){
                     that.props.history.push('/sign-in');
                }
        });  
    }

    onPageChange = (page) => {  
        this.state.page = page;     
       
        this.fetchData({ status: this.state.status });
    }

    onMessage(event) {
        const socketData = JSON.parse(event.data);
        const device_id = localStorage.getItem('device_id');
       
        const requestParam = {
            language_id: 1,
            device_id: device_id,
        }

        if (socketData.requesttype == "orderstatus" && socketData.customer_device_id == device_id && socketData.customer_cancel == 'yes') {                  
           document.getElementById("order_status_"+ socketData.order_id).innerText ="CANCELLED";

        }

        if (socketData.requesttype == "orderstatus" && socketData.customer_device_id == device_id && socketData.item_collected == 'yes') {
            //document.getElementById("order_status_"+ socketData.order_id).innerHTML="ITEM COLLECTED";
        }

        if (socketData.requesttype == "orderstatus" && socketData.customer_device_id == device_id && socketData.product_ds_or_not == 'yes') {            
            document.getElementById("order_status_"+ socketData.order_id).innerText ="DELIVERED";
        }

        if (socketData.requesttype == "orderstatus" && socketData.customer_device_id == device_id && socketData.status == 'shop_confirmed') {
            document.getElementById("order_status_"+ socketData.order_id).innerText ="CONFIREMD";
        }

    }

    onSearch = e => {
		const value = e.currentTarget.value;
        this.setState({
            status: '',
            keyword: value
        })
		if(value.length >= 3 || value.length === 0){
			this.state.page = 1;			
			this.fetchData( { keyword: value } );          
		}		
	}

    onChangeFilter = (event) => {
       
        const target = event.currentTarget;
        var value = target.value;

        this.state.keyword = '';
        this.state.page = 1;
        this.state.status = value;
        this.fetchData( { status: value } );
    }

    render(){

        const orderList = this.state.order_list;
        const isLoading =  this.state.isLoading;
        const { pager } = this.state;

        return(
            <>
                {
                     isLoading == false ? 
                    <>
                        <section className="parallax-window" id="cs_short" style={{backgroundImage: `url(${AWS_IMG_PATH + "extra_images/shop_detail_default_banner.png"})`}}>
                            <div id="subheader">
                                <div id="sub_content">
                                <h1>Order List</h1>                
                                </div>
                            </div>
                        </section>
    
                        <div className="container " style={{marginTop: "20px"}}>
                            <div className='search_area'>
                                <div className="row">                            
                                    <div className="col-lg-3">
                                        <input type='text' className='form-control' value={this.state.keyword} placeholder='Search by Order ID' onChange={e => this.onSearch(e)}/>
                                    </div>
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-3">
                                   
                                            <select id='saveScheduleTime' className="form-control" onChange={this.onChangeFilter}>
                                               <option value=''>All</option>
                                               <option value='active' selected={this.state.status == "active" }>Active</option>
                                               <option value='delivered' selected={this.state.status == "delivered" }>Delivered</option>
                                               <option value='cancelled' selected={this.state.status == "cancelled" }>Cancelled</option>                                                                                          
                                            </select>
                                    </div>
                                </div>

                           
                            </div>
                            <div  key={this.state.unique_id}>
                                { 
                                orderList.length != 0 ?
                                orderList.map( (orderData,i) => {                                                                
                                        return(                                
                                            <OrderListItem key={i} order_data={orderData}/>
                                        )
                                    })
                                : <div className="no_data_found">No, any order found.</div>
                                }
                            </div>
                        
    
                            <div className="card-footer pb-0 pt-3" >
                                { orderList.length != 0 &&
                                    <ul className="pagination">
                                    
                                        {pager.pages.map(page =>                                          
                                            <li key={page} className={`page-item number-item ${pager.page === page ? 'active' : ''}`}>
                                                <HashLink
                                                    to={`/order_list/#cs_short`}
                                                    className="page-link"
                                                    onClick={ (e) => this.onPageChange(page)}
                                                >          
                                                    {page}
                                                </HashLink>  
                                            </li>
                                        )}
                                        
                                    </ul>
                                }                    
                            </div>
                        </div>
                    </>
                 : <OrderListSkeleton />
                }
               
            </>
        )
    }
}

export default OrderList;