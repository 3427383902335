import React, { Component } from 'react'
import { Helmet } from 'react-helmet';

class Landing extends Component {
    
    render() {
        return (
            <>
            <Helmet>
                <link href="/css/landing_page/all.css" rel="stylesheet"/>
                <link  href="/css/landing_page/style.css" rel="stylesheet" />
                <link href="/css/landing_page/bootstrap.min.css" rel="stylesheet"/>
                <link href="/css/landing_page/style.css" rel="stylesheet"/>
                <link href="/css/landing_page/responsive.css" rel="stylesheet"/>
            </Helmet>
            <div className="page-wrapper"  dir="rtl">
                <div className="top-slide">
                    <div className="header_sticky_cstm">
                        <div className="text-center">
                            <a href="/sign-in" className="login_buttn" style={{ textDecoration: 'none' }}>Login</a>
                        </div>
                    </div>
                    <div className="container">
                        <div className="logo-part">
                            <img className="img-fluid" src="img/landing_images/images/logo.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="second-slide">
                    <div className="container">
                        <img className="img-fluid second-slide-img" src="img/landing_images/images/img-1.png" alt="" />
                        <p>لافندر هي مؤسسة رائدة في مجال الخدمات اللوجستية حيث توفر إدارة وتشغيل عمليات النقل بجودة عالية في جميع انحاء المملكة.</p>
                        <p>تفخر المؤسسة بجذورها السعودية وتعمل بنجاح في المملكة العربية السعودية وهي على دراية تامة ببيئة الاعمال والإطار التنظيمي واللوجستي.</p>
                        <p>يقدم فريق لافندر حلول لوجستية لعملائنا حيث لدينا خدمات استشارية ورؤى حول كيفية تحقيق هدفك من خلال العمل جنبا إلى جنب مع فريقنا التشغيلي وشركائنا للتنفيذ نيابة عنك. نحن مزود رئيسي لتحويل الأعمال وتحسينها عبر خدماتنا.</p>
                        <p>خبرة فريقنا وتراثنا الإقليمي والاستثمار المستمر في التكنولوجيا هي التي تجعلنا روادا في تقديم خدمات عالية الجودة. نحن متخصصون وعلى هذا النحو فإن اهتمامنا بالتفاصيل والتنفيذ هو اولوية بالنسبة لنا.</p>
                    </div>
                </div>
                <div className="third-slide">
                    <div className="img-part">
                        <img className="img-fluid third-slide-img" src="img/landing_images/images/img-2.png" alt="" />
                    </div>
                    <div className="content-part">
                        <div className="content-inner-part">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="icon-part">
                                                    <img className="img-fluid d-block mx-auto" src="img/landing_images/images/icon-2.png" alt="" />
                                                </div>
                                                <h3 className="card-title">رؤيتنــا</h3>
                                                <blockquote>أن نكون الحل للابتكار وتجربة العملاء الرائدة في السوق</blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="icon-part">
                                                    <img className="img-fluid d-block mx-auto" src="img/landing_images/images/icon-1.png" alt="" />
                                                </div>
                                                <h3 className="card-title">مهمتنــا</h3>
                                                <ul className="list">
                                                    <li>الاستماع والابتكار وتقديم خدمات رائدة في السوق تعتمد على القيمة والتكنولوجيا لعملائنــا</li>
                                                    <li>أن نكون ركيزة أساسية في نجاح خطط عملائنـا</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 text-center">
                            <div className="col-6 title-part">
                                <h2>الحلـــول</h2>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>
                    <div className="container">
                        <ul className="list">
                            <li>
                                <div className="inner">
                                    <h3>حلول العملاء والتوريد B2B</h3>
                                    <p>حلول لافندر لتوريد ونقل الشحنات بكفاءة وسرعة حسب طلب العملاء، يقوم خبرائنا في التشغيل بتنفيذ حلول مرنة مصممة خصيصا لعملائنا.</p>
                                </div>
                            </li>
                            <li>
                                <div className="inner">
                                    <h3>حلول تأجير الشاحنات</h3>
                                    <p>حلول لافندر بتقديم خيارات متعددة للقطاعات بما يناسب عملها، حيث نقدم خدمة تأجير شاحنات من أنواع واحجام مختلفة بما يتناسب مع عملائنا لنقل بضائعهم إلى أي مدينة أو منطقة داخل السعودية.</p>
                                </div>
                            </li>
                            <li>
                                <div className="inner">
                                    <h3>حلول نقل الموظفين</h3>
                                    <p>حلول لافندر لنقل موظفي القطاعات من والى مواقع العمل عبر وسائل نقل حديثة مجهزة وآمنة بما يناسب عملائنا.</p>
                                </div>
                            </li>
                            <li>
                                <div className="inner">
                                    <h3>حلول أنظمة لوجستية</h3>
                                    <p>حلول لافندر لشركات الخدمات اللوجستية، حيث نوفر أنظمة مرنة تربط شركــات الخدمـات اللوجستية بعملائها مما يجعل عمل الشركات أكثر كفاءة واحترافية.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="fifth-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="d-flex align-items-center justify-content-center title-part">
                                    <h2>لوجستيات الطرف الثالث 3PL</h2>
                                    <img className="img-fluid title-icon" src="img/landing_images/images/title-icon.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ul className="list1">
                                    <li>
                                        <h3>التوزيع</h3>
                                        <p>خبرتنا في مجال التوصيل تضمن توزيع منتجاتك في الوقت المناسب وبشكل فعال من حيث التكلفة:</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ul className="list">
                                    <li>تم انشاء حلول نقل الطرف الثالث بما يتناسب مع احتياج عملك.</li>
                                    <li>يتم تزويد شركائنا بالتوصيات والأفكار لتطوير وتنفيذ أفضل نموذج تشغيلي.</li>
                                    <li>نمتلك أكثر من 50 مركبة يتم التحكم في درجة حرارة التبريد لتلبية جمع الاحتياجات</li>
                                    <li>نقدم حلول التوصيل (جاف – مبرد – مجمد) لجميع المناطق.</li>
                                    <li>تشغيل العمليات على مدار الساعة 7 / 24</li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6">
                                <ul className="list">
                                    <li>تتم إدارة عملية النقل من خلال نظام لوجستي خاص بلافندر في إدارة الطلبات تم إنشاؤه من قبل مطورينا.</li>
                                    <li>تقديم الأفكار وخطط سير العمل قبل المباشرة بالعمليات لضمان اعلى المعايير التشغيلية وتنفيذها بشكل جيد.</li>
                                    <li>خبرة في التوصيل الى جميع نقاط التسليم لتجار التجزئة والجملة والمطاعم والمتاجر.</li>
                                    <li>يحتوي اسطولنا على نظام تحديد المواقع GPS لضمان عملية النقل.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third-slide">
                    <div className="img-part">
                        <img className="img-fluid third-slide-img" src="img/landing_images/images/singlecar.png" alt="" />
                    </div>
                </div>
                <div className="sixth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0">
                            <div className="col-12 col-sm-6">
                                <img className="img-fluid w-100 h-100" src="img/landing_images/images/img-12.jpg" alt="" />
                            </div>
                            <div className="col-12 col-sm-6">
                                <img className="img-fluid w-100 h-100" src="img/landing_images/images/img-11.jpg" alt="" />
                            </div>
                            <div className="col-12 col-sm-6">
                                <img className="img-fluid w-100 h-100" src="img/landing_images/images/img-14.jpg" alt="" />
                            </div>
                            <div className="col-12 col-sm-6">
                                <img className="img-fluid w-100 h-100" src="img/landing_images/images/img-13.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="seventh-slide">
                    <div className="container">
                        <div className="row g-0 align-items-center">
                            <div className="col-12 col-md-5">
                                <div className="img-wrap">
                                    <img className="img-fluid mx-auto d-block" src="img/landing_images/images/seventh-slide-img.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-7">
                                <div className="inner">
                                    <p>نقدم خيارات متعددة من أنواع وأحجام المركبات (توريد – توزيع – تأجير – نقل موظفين – أنظمة لوجستية) بحسب ما يناسب عملائنا من وإلى أي مدينة أو منطقة داخل السعودية بالإضافة إلى تأميناً على البضائع (حسب الطلب) وتقديم المساعدة اللازمة وجدولة الطلبات، ومميزات أخرى أكثر تغير مفهوم النقليات والنقل البري لتكون أكثر سهولة وأمان.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eightth-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-8">
                                <div className="d-flex align-items-center justify-content-center title-part">
                                    <h2>رفع وجدولة الطلبات</h2>
                                    <img className="img-fluid title-icon" src="img/landing_images/images/title-icon1.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-4"></div>
                        </div>
                        <p>نصمم خياراتنا لتكون بالمتناول وبأسهل الطرق حيث يمكن للعملاء طلب <br></br>الشاحنات وجدولة النقليات عبر المنصة أو عن طريق فريق عمل لافندر.</p>
                    </div>
                </div>
                <div className="ninghtth-slide">
                    <img className="img-fluid mx-auto d-block w-100" src="img/landing_images/images/slide-img.jpg" alt="" />
                </div>
                <div className="tenth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-3.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>الأنظمة والتحكم</h2>
                                    <p>نقدم لعملاءنا خدمات مجانية مصاحبة لعقود الخدمات اللوجستية تتمثل في أنظمة مراقبة الطلبات ولوحات تحكم بتتبع المركبات ومراقبة سلوك السائقين.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eleventh-slide">
                    <img className="img-fluid w-100" src="img/landing_images/images/img-21.jpg" alt="" />
                    <img className="img-fluid w-100" src="img/landing_images/images/img-22.jpg" alt="" />
                    <img className="img-fluid w-100" src="img/landing_images/images/img-23.jpg" alt="" />
                    <img className="img-fluid w-100" src="img/landing_images/images/img-24.jpg" alt="" />
                </div>
                <div className="twelthth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center flex-row-reverse">
                            <div className="col-12 col-sm-6 green-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/car.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>تتبع الطلبات</h2>
                                    <p>خدمة تتبع متطورة لحظة بلحظة تمكن العملاء من معرفة مواقع (الطلبات – المركبات) في أي وقت عبر المنصة، لتسهيل المتابعة أول بأول.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="thirteenth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-4.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>خيارات متعددة</h2>
                                    <p>مهما كان نوع أو حجم العمل، نوفر المركبة المناسبة حجما وقيمة بداية من الحمولات الصغيرة او الحافلات بما يناسب العملاء.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourteenth-slide">
                    <img className="img-fluid w-100" src="img/landing_images/images/fourteenth-part-img.jpg" alt="" />
                </div>
                <div className="sixteenth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center flex-row-reverse">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-5.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>نظام حسابات</h2>
                                    <p>فواتير الإلكترونية ترسل شهريا الى مسؤول الحسابات. ونظام حسابات متقدم وكشف عمليات يمكن الوصول إلى تفاصيل دقيقة عبر لوحة تحكم (Dashboard) توفرها المنصة للعملاء.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="seventeenth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-6.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>تغطيتنا</h2>
                                    <p>تغطيتنا الشاملة تضمن النقل داخل المملكة العربية السعودية بنظام تسعير آلي حسب المسافة تغني الشركات عن بذل مجهود لتسعير وجهات جديدة من أجل نقل موظفيها او إيصال بضائعها إلى مستودعاتها أو لعملائها.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eighteenth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0">
                            <div className="col-12 col-sm-6">
                            <img className="img-fluid w-100" src="img/landing_images/images/eighteenth-img1.jpg" alt="" />  
                            </div>
                            <div className="col-12 col-sm-6">
                                <img className="img-fluid w-100" src="img/landing_images/images/eighteenth-img2.jpg" alt="" />  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nineteenth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center flex-row-reverse">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-7.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>نظام حسابات</h2>
                                    <p>فواتير الإلكترونية ترسل شهريا الى مسؤول الحسابات. ونظام حسابات متقدم وكشف عمليات يمكن الوصول إلى تفاصيل دقيقة عبر لوحة تحكم (Dashboard) توفرها المنصة للعملاء.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="twentieth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-8.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>تغطيتنا</h2>
                                    <p>تغطيتنا الشاملة تضمن النقل داخل المملكة العربية السعودية بنظام تسعير آلي حسب المسافة تغني الشركات عن بذل مجهود لتسعير وجهات جديدة من أجل نقل موظفيها او إيصال بضائعها إلى مستودعاتها أو لعملائها.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="twentyoneth-slide">
                    <div className="container-fluid p-0">
                        <div className="row g-0 align-items-center flex-row-reverse">
                            <div className="col-12 col-sm-6 light-bg">
                                <div className="img-wrap">
                                    <img className="img-fluid left-img" src="img/landing_images/images/icon-9.png" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 text-part">
                                <div className="inner-wrap">
                                    <h2>نظام حسابات</h2>
                                    <p>فواتير الإلكترونية ترسل شهريا الى مسؤول الحسابات. ونظام حسابات متقدم وكشف عمليات يمكن الوصول إلى تفاصيل دقيقة عبر لوحة تحكم (Dashboard) توفرها المنصة للعملاء.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondlast-slide">
                    <div className="container">
                        <div className="slide-title">
                            <h2>شركائنا</h2>
                        </div>
                        <div className="inner-wrap first-wrap">
                            <h3>قطاع التموين والتوصيل</h3>
                            <ul>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-1.png" alt="" />
                                    <h4>هاف مليون</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-2.png" alt="" />
                                    <h4>ليلو</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-3.png" alt="" />
                                    <h4>لوغرونييه</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-4.png" alt="" />
                                    <h4>كوستا كوفي</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-5.png" alt="" />
                                    <h4>كاريبو كوفي</h4>
                                </li>
                            </ul>
                        </div>
                        <div className="inner-wrap second-wrap">
                            <h3>قطاع التوريد</h3>
                            <ul>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-6.png" alt="" />
                                    <h4>تمرية</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-7.png" alt="" />
                                    <h4>بيك اواي</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-8.png" alt="" />
                                    <h4>تريستي كوفي</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-9.png" alt="" />
                                    <h4>باستيل</h4>
                                </li>
                                <li>
                                    <img className="img-fluid" src="img/landing_images/images/company-10.png" alt="" />
                                    <h4>هوبو</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bottom-slide">
                    <div className="inner-wrap">
                    <div className="container">
                            <div className="slide-title">
                                <h2>للتواصل</h2>
                            </div>
                            <div className="second-inner">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                        <div className="d-flex align-items-center email">
                                            <img className="img-fluid" src="img/landing_images/images/email.png" alt="" />
                                            <div>
                                                <a href="mailto:Care@lavenderapp.net">Care@lavenderapp.net</a><br></br>
                                                <a href="mailto:info@lavenderapp.net">info@lavenderapp.net</a>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                        <div className="d-flex align-items-center phone">
                                            <img className="img-fluid" src="img/landing_images/images/phone.png" alt="" />
                                            <div>
                                                <a href="tel:0559979956">0559979956</a><br></br>
                                                <a href="tel:0504274882">0504274882</a>
                                            </div>
                                            <div className="mr-50">
                                                <a href="tel:0508500685">0508500685</a><br></br>
                                                <a href="tel:0554403144">0554403144</a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
export default Landing;
