import "./Skeleton.css";

import React from "react";

const ViewCartSkeleton = () => {

  return (
        <div className="theiaStickySidebar skeleton-theiaStickySidebar">
            <div id="cart_box" className="skeleton-cart_box">
                <h3 className="skeleton-h"></h3>
                <table className="table table_summary skeleton-cart_table">
                    <tbody>
                        <tr>
                            <td colSpan="2" className="skeleton-tr"></td>                        
                        </tr>
                        <tr>
                            <td colSpan="2" className="skeleton-tr"></td>                         
                        </tr>
                        <tr>
                            <td colSpan="2" className="skeleton-tr"></td>                         
                        </tr>                                        
                    </tbody>
                </table>
                <hr />
                <div className="row" id="options_2">
                    <div className="col-xl-6 col-md-12 col-sm-12 col-6">
                        <div className="skeleton-cart-row"></div>
                    </div>
                    <div className="col-xl-6 col-md-12 col-sm-12 col-6">
                        <div className="skeleton-cart-row"></div>
                    </div>
                </div>
                <hr />
                <table className="table table_summary skeleton-cart_table">
                    <tbody>
                        <tr>
                            <td colSpan="2" className="skeleton-tr"></td>                        
                        </tr>
                        <tr>
                            <td colSpan="2" className="skeleton-tr"></td>                         
                        </tr>
                        <tr>
                            <td colSpan="2" className="skeleton-tr"></td>                         
                        </tr>    
                    </tbody>
                </table>           
                <a className="btn_full1 skeleton-btn" href=""></a>
            </div>
        </div>
  );
};

export default ViewCartSkeleton;