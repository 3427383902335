import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import queryString from 'query-string';
import {ORDER_DETAIL, AWS_IMG_PATH, B2BSOCKET_URL} from '../Comman/Constant';
import { prettyDOM } from '@testing-library/react';

class OrderPlaced extends Component {

    constructor(props){
        super(props);
        const params = queryString.parse(this.props.location.search);
        this.state = {    
            order_data: [],     
            shop_data: [] ,
            cart_details : [],   
            selectedCard:[],          
            coupon_discount: 0,
            delivery_cost: 0,       
            delivery_discount: 0,
            subtotal:0,
            selectedCartData: {
                cvc:"",
                expire_month:"",
                name_card_order:"",
                card_number:"",
                focused:"",
            },
            order_status: "",
            payment_status: "",
            item_collected: "",
            product_ds_or_not: "",        
        }       
    }

    componentDidMount(){    
        this.conn = new WebSocket(B2BSOCKET_URL);
        
        this.fetchData();
  }

  fetchData = async () => {
    var that = this;
    const device_id = localStorage.getItem('device_id');
    await axios.post(ORDER_DETAIL, {
        language_id: 1,
        device_id: device_id,
        order_id: this.props.match.params.id,
        from:"place_order"
    },{ 
        headers: {
        'Authorization': device_id 
        }
    }).then((response) => {        
        if(response.data.code == 201){
            this.props.history.push('/order_list')
        } else {
            this.setState({
                order_data: response.data.cart_details.order_details ? response.data.cart_details.order_details : [],
                order_id: response.data.cart_details.order_details.id,
                customer_cancel: response.data.cart_details.order_details.customer_cancel,
                customer_id: response.data.cart_details.order_details.customer_id,               
                shop_data : response.data.cart_details.shop_details,            
                cart_details : response.data.cart_details.cart_products_new_one ? response.data.cart_details.cart_products_new_one : [],                                
                selectedCartData : response.data.selectedCartData ? response.data.selectedCartData : [],   
                delivery_cost: response.data.delivery_cost,    
                delivery_discount: response.data.delivery_discount, 
                coupon_discount: response.data.coupon_discount, 
                subtotal: response.data.subtotal,  
                final_amount: response.data.final_amount,        
                unique_id: response.data.unique_id,
                order_status: response.data.cart_details.order_details.status,
                payment_method_label: response.data.cart_details.order_details.product_payment_type, 
                payment_code: response.data.cart_details.order_details.payment_code,           
                payment_status: response.data.cart_details.order_details.payment_status,                                    
            });  
                
            const socketData = {
                device_id: this.state.order_data.device_id,
                order_id: this.state.order_id,
                order_type: this.state.order_data.schedule_order == 'yes' ? "schedule" : "now",
                requesttype:"checkout",
                action:"order_place",
                device_type:"web",
                source_type:"b2b",                
                city_id:"9",
                ask_to_confirm:'yes',
                sub_admin_id:response.data.sub_admin_id
            }
            setTimeout(function(){      
                if(that.state.order_data.socket_notification == 0){
                    that.conn.send(JSON.stringify(socketData));
                }                          
            }, 1000);

               
            }      
          }).catch(function (error) {                                           
            that.props.history.push('/sign-in');              
        });
    }

  viewOrder = () => {
    this.props.history.push('/order_detail/'+this.props.match.params.id);
  }

    render(){

        const orderData = this.state.order_data;
        const shopData = this.state.shop_data;
        const deliveryDetails = this.state.delivery_details;
        const cartDetails = this.state.cart_details;
        const selectedCartData = this.state.selectedCartData;
    
        return(
            <div>
                <section className="parallax-window" id="cs_short" style={{backgroundImage: `url(${AWS_IMG_PATH + "extra_images/shop_detail_default_banner.png"})`}}>
                        <div id="subheader">
                            <div id="sub_content">
                                <div className="bs-wizard row">
                                    <div className="col-4 bs-wizard-step complete">
                                        <div className="text-center bs-wizard-stepnum"><strong>1.</strong> Your details</div>
                                        <div className="progress">
                                            <div className="progress-bar"></div>
                                        </div>                                
                                        <Link className="bs-wizard-dot"></Link>
                                    </div>
                                    <div className="col-4 bs-wizard-step complete">
                                        <div className="text-center bs-wizard-stepnum"><strong>2.</strong> Payment</div>
                                        <div className="progress">
                                            <div className="progress-bar"></div>
                                        </div>
                                        <Link className="bs-wizard-dot"></Link>                                
                                    </div>
                                    <div className="col-4 bs-wizard-step active">
                                        <div className="text-center bs-wizard-stepnum"><strong>3.</strong> Finish!</div>
                                        <div className="progress">
                                            <div className="progress-bar"></div>
                                        </div>
                                       <Link className="bs-wizard-dot"></Link>   
                                    </div>
                                </div>                
                            </div>
                        </div>
                    </section>
                
                <div className="container margin_60_35">
                    <div className="row">
                        <div className="col-lg-3">
                                                   
                        </div>
                        <div className="col-lg-6">
                            <div className="box_style_2" id="order_process">
                                <h2 className="inner">Order placed!</h2>
                                <div id="confirm">
                                    <i className="icon_check_alt2"></i>
                                    <h3>Thank you!</h3>
                                    
                                </div>
                                <h4>Summary</h4>
                                <table className="table table-striped nomargin">
                                    <tbody>
                                    {
                                        cartDetails ? 
                                            cartDetails.map( (cartData,cartIndex) => {
                                                let rowCount = 1;
                                                return cartData.weight_products.map( (weightData,weightIndex) => {
                                                    let productType = weightData.product_selling_type;
                                                    return(
                                                     
                                                        <tr key={weightIndex}>
                                                          
                                                            <td>
                                                                
                                                                {weightData.product_name}
                                                                <br></br>
                                                                <strong>{weightData.product_quantity}x{weightData.weight}</strong>
                                                              
                                                                
                                                            </td>
                                                             <td>
                                                                {/* <strong className="float-right">SAR {weightData.product_fixed_price}</strong> */}
                                                            </td>
                                                        </tr> 
                                                       
                                                    )
                                                   
                                                    rowCount = rowCount + 1;
                                                })
                                                
                                            })
                                        : 
                                        null
                                    }

                                        <tr>
                                            <td>
                                                Payment Type: 
                                            </td>
                                            <td className="text-right">
                                                <>
                                                <strong>
                                                {this.state.payment_method_label} 
                                                {
                                                    orderData.credit_date &&  <strong> ( { orderData.credit_date } ) </strong>
                                                }
                                                </strong>
                                                {
                                                selectedCartData.card_number &&
                                                <div className="credit-card-show-last-detail"> { selectedCartData.card_number.substr(selectedCartData.card_number.length - 4) }  </div>
                                                                                                
                                                }
                                                </>
                                            </td>
                                        </tr>   
                                        {/* <tr>
                                            <td>
                                                Delivery Time: 
                                            </td>
                                            <td className="text-right">
                                                <strong>
                                                {orderData.schedule_order == 'yes' ?
                                                 "Schedule - "+ orderData.schedule_date
                                                : "Now"}
                                                </strong>
                                            </td>
                                        </tr>   */}
                                        {
                                            orderData.special_request_note &&
                                            <tr>
                                            <td>
                                                Notes for the restaurant: 
                                            </td>
                                            <td className="text-right">
                                                <strong>
                                                {orderData.special_request_note }
                                                </strong>
                                            </td>
                                            </tr> 
                                        }
                                      
                                        {/* <tr>
                                            <td>
                                                Sub Total: 
                                            </td>
                                            <td className="text-right">
                                                <strong>
                                                    SAR {this.state.subtotal}
                                                </strong>
                                            </td>
                                        </tr> 
                                        <tr>
                                            <td>
                                                Delivery Cost: 
                                            </td>
                                            <td className="text-right">
                                                <strong>
                                                    SAR {this.state.delivery_cost}
                                                </strong>
                                            </td>
                                        </tr>  */}
                                    
                                        
                                        {/* <tr>
                                            <td className="total_confirm">
                                                TOTAL
                                            </td>
                                            <td className="total_confirm">
                                                <span className="float-right">SAR {this.state.final_amount}</span>
                                            </td>
                                        </tr>  */}
                                         <tr>                                        
                                            <td colSpan="2">
                                               <input type="button" className="btn_full deliver_here_btn" value="VIEW ORDER" onClick={this.viewOrder}></input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                            
                            </div>
                        </div>    
                        <div className="col-lg-3" id="sidebar">
                                       
                        </div>
                    </div>                
                </div>
        
            </div>
        )
    }
}

export default OrderPlaced;