import React, { Component } from 'react';
import { Link } from "react-router-dom";

import CartSummeryWidegt from '../Widget/cartSummeryWidget';

class OrderStepTwo extends Component {

    render(){
    
        return(
            <div>
                <section className="parallax-window" id="short" data-parallax="scroll" data-image-src="img/sub_header_cart.jpg" data-natural-width="1400" data-natural-height="350">
                    <div id="subheader">
                        <div id="sub_content">
                            <h1>Place your order</h1>
                            <div className="bs-wizard row">
                                <div className="col-4 bs-wizard-step complete">
                                    <div className="text-center bs-wizard-stepnum"><strong>1.</strong> Your details</div>
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>                                
                                    <Link className="bs-wizard-dot" to="/order_step_one"></Link>
                                </div>
                                <div className="col-4 bs-wizard-step active">
                                    <div className="text-center bs-wizard-stepnum"><strong>2.</strong> Payment</div>
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>
                                    <Link className="bs-wizard-dot" to="/order_step_two"></Link>                                
                                </div>
                                <div className="col-4 bs-wizard-step disabled">
                                    <div className="text-center bs-wizard-stepnum"><strong>3.</strong> Finish!</div>
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>
                                    <a href="cart_3.html" className="bs-wizard-dot"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="position">
                    <div className="container">
                        <ul>
                            <li><a href="#0">Home</a></li>
                            <li><a href="#0">Order</a></li>
                            <li>Step 2</li>
                        </ul>                    
                    </div>
                </div>

                <div className="container margin_60_35">
                    <div className="row">
                        <div className="col-lg-3">
                            <div class="box_style_2 info d-none d-sm-block">
                                <h4 class="nomargin_top">Delivery time <i class="icon_clock_alt float-right"></i></h4>
                                <p>
                                    Lorem ipsum dolor sit amet, in pri partem essent. Qui debitis meliore ex, tollit debitis conclusionemque te eos.
                                </p>
                                <hr />
                                <h4>Secure payment <i class="icon_creditcard float-right"></i></h4>
                                <p>
                                    Lorem ipsum dolor sit amet, in pri partem essent. Qui debitis meliore ex, tollit debitis conclusionemque te eos.
                                </p>
                            </div>
                            <div class="box_style_2 d-none d-sm-block" id="help">
                                <i class="icon_lifesaver"></i>
                                <h4>Need <span>Help?</span></h4>
                                <a href="tel://004542344599" class="phone">+45 423 445 99</a>
                                <small>Monday to Friday 9.00am - 7.30pm</small>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box_style_2" id="order_process">
                                <h2 className="inner">Payment methods</h2>
                                <div class="payment_select">
                                    <label><input type="radio" value="" checked name="payment_method" class="icheck" />Credit card</label>
                                    <i class="icon_creditcard"></i>
                                </div>
                                <div class="form-group">
                                    <label>Name on card</label>
                                    <input type="text" class="form-control" id="name_card_order" name="name_card_order" placeholder="First and last name" />
                                </div>
                                <div class="form-group">
                                    <label>Card number</label>
                                    <input type="text" id="card_number" name="card_number" class="form-control" placeholder="Card number" />
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Expiration date</label>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <input type="text" id="expire_month" name="expire_month" class="form-control" placeholder="mm" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <input type="text" id="expire_year" name="expire_year" class="form-control" placeholder="yyyy" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Security code</label>
                                            <div class="row">
                                                <div class="col-md-4 col-sm-6">
                                                    <div class="form-group">
                                                        <input type="text" id="ccv" name="ccv" class="form-control" placeholder="CCV" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 col-sm-6">
                                                    <img src="img/icon_ccv.gif" width="50" height="29" alt="ccv" /><small>Last 3 digits</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="payment_select" id="paypal">
                                    <label><input type="radio" value="" name="payment_method" class="icheck" />Pay with paypal</label>
                                </div>
                                <div class="payment_select nomargin">
                                    <label><input type="radio" value="" name="payment_method" class="icheck" />Pay with cash</label>
                                    <i class="icon_wallet"></i>
                                </div>
                            </div>
                        </div>    
                        <div className="col-lg-3" id="sidebar">
                           
                            <CartSummeryWidegt />          
                                                 
                        </div>
                    </div>                
                </div>
        
            </div>
        )
    }
}

export default OrderStepTwo;