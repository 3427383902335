import React, { Component } from 'react';
import axios from "axios";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Autocomplete from "react-google-autocomplete";

import { SHOP_EXISTS_API_URL, CREATE_CUSTOMER, MAP_GOOGLE_KEY } from '../Comman/Constant';

class  GoogleMapForLocation extends React.Component{

    constructor(){
        super();

        this.state = {                  
            customerAddress : "",
          };         
    }


    componentDidMount(){
      
      navigator.geolocation.getCurrentPosition(

        function(position) {

          const { latLng } = position.coords;
          const currentPosition = position;
          const currentLatitude = position.coords.latitude;
          const currentLongitude = position.coords.longitude;
                
          const google = window.google
          let geocoder = new google.maps.Geocoder();
          let customerAddress = '';

          geocoder.geocode({
            latLng: { lat: currentLatitude, lng: currentLongitude}
          }, function(responses) {
            
            if (responses && responses.length > 0) {            
                customerAddress = responses[0].formatted_address;
                this.setState({
                  customerLat: currentLatitude,
                  customerLng: currentLongitude,  
                  customerLatReset: currentLatitude,
                  customerLngReset: currentLongitude, 
                  customerAddressReset: customerAddress, 
                  customerAddress: customerAddress,              
                });
               
                localStorage.setItem('customerLatSelection',currentLatitude);
                localStorage.setItem('customerLongSelection', currentLongitude);
            }
          }.bind(this));
                
          
        }.bind(this),
        function(error) {
          const currentError = error.message;  
          this.setState({
            currentError: currentError,
            currentErrorCode: error.code,
          });   
        }.bind(this),
        {
          maximumAge:10000, timeout:5000, enableHighAccuracy: true
        }
       
      );
    }

    resetAddress = () => {
      this.setState({
            customerLat: this.state.customerLatReset,
            customerLng: this.state.customerLngReset,            
            customerAddress: this.state.customerAddressReset,              
          });
    }

    deliver_here = () => {
      var that = this;
        axios.post(SHOP_EXISTS_API_URL, {
            language_id: 1,
            cust_lat: localStorage.getItem('customerLatSelection'),
            cust_lng: localStorage.getItem('customerLongSelection'),
            device_id: localStorage.getItem('device_id'),    
        },{ 
                headers: {
                'Authorization': localStorage.getItem('device_id') 
                }
            })
        .then((response) => {
            if(response.data.shop_exits == true){
                localStorage.setItem('customerLat', localStorage.getItem('customerLatSelection'));
                localStorage.setItem('customerLng', localStorage.getItem('customerLongSelection'));
                localStorage.setItem('device_id', response.data.device_id);
                localStorage.setItem('customerLocation',this.state.customerAddress);

                this.props.history.push('/');
                
            } else {
              confirmAlert({

                customUI: ({ onClose }) => {
                  return (
                    <div className='react-confirm-alert-body'>
                      <p className="text-danger cant_delivery_here">{response.data.error_message}</p>
					            <div className="react-confirm-alert-button-group">
                      <button onClick={onClose}>Okay</button>
                      
                      </div>                  
                    </div>
                  );
                }
              });
            }
        }).catch(function (error) {               
              if(error.response.status==401){
                    that.props.history.push('/sign-in');
              }
        });
    }

    onMarkerDragEnd = (coord, from_action = '') => {

        const { latLng } = coord;
              
        const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const google = window.google
        let geocoder = new google.maps.Geocoder();
        let customerAddress = '';

        geocoder.geocode({
          latLng: latLng
        }, function(responses) {
          if (responses && responses.length > 0) {            
              customerAddress = responses[0].formatted_address;
              this.setState({
                customerLat: currentLatitude,
                customerLng: currentLongitude,  
                customerAddress: customerAddress,
              });
             
              localStorage.setItem('customerLatSelection',currentLatitude);
              localStorage.setItem('customerLongSelection', currentLongitude);
          }
        }.bind(this));
    };

    mapClicked = (mapProps, map, clickEvent) => {
    
     const latLng  = clickEvent.latLng;
  
      const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const google = window.google
        let geocoder = new google.maps.Geocoder();
        let customerAddress = '';

        geocoder.geocode({
          latLng: latLng
        }, function(responses) {
          if (responses && responses.length > 0) {            
              customerAddress = responses[0].formatted_address;
              this.setState({
                customerLat: currentLatitude,
                customerLng: currentLongitude,  
                customerAddress: customerAddress,
              });
             
              localStorage.setItem('customerLatSelection',currentLatitude);
              localStorage.setItem('customerLongSelection', currentLongitude);
          }
        }.bind(this));

        

    }   

    onPlaceSelected = (place) => {
        let customerAddress = place.formatted_address;
          const currentLatitude = place.geometry.location.lat();
          const currentLongitude = place.geometry.location.lng();
      
          this.setState({
            customerLat: currentLatitude,
            customerLng: currentLongitude,  
            customerAddress: customerAddress,
          });
        
          localStorage.setItem('customerLatSelection',currentLatitude);
          localStorage.setItem('customerLongSelection', currentLongitude);
    }

    render(){
        const mapStyles = {
            width: '100%',
            height: '500px',
          };
      
          return(
            <>          
            {
              this.state.customerLat && 
              <div id="customer_location_map">
                <div className='autocomplete_div'>
                  <Autocomplete
                      apiKey={MAP_GOOGLE_KEY}
                      onPlaceSelected={(place) => this.onPlaceSelected(place)}
                      options={{
                          types: ["geocode", "establishment"],
                      }}
                  />
                </div>
              <Map
              key={new Date().getTime()}
              google={this.props.google}
              zoom={14}
              style={mapStyles}
              initialCenter={{ lat: this.state.customerLat, lng: this.state.customerLng}}
              onClick={this.mapClicked}
              >
                <Marker
                position={{ lat: this.state.customerLat, lng: this.state.customerLng}}
                draggable={true}
                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}      
                />
              </Map>
            </div>
            }
            
            <div className="customerLocationInfo container margin_60_35">
              {
                this.state.currentErrorCode == 1 &&
                <div className="row">
                    <div className="col-lg-6 choosenLocation">
                        <span className="font-weight-bold">Error:</span>
                        <span className="customerSelectedAddress">Please allow location permission</span>
                    </div>
                </div>
              }
              {
                this.state.customerLat && 
                <div className="row">
                    <div className="col-lg-6 choosenLocation">
                        <span className="font-weight-bold">Deliver to:</span>
                        <span className="customerSelectedAddress">{this.state.customerAddress}</span>
                    </div>
                    <div className="col-lg-6">
                        <div className="col-md-12">
                            <label>Add delivery instructions (optional)</label>
                            <textarea className="form-control" style={{height:'50px'}} placeholder="" name="notes" id="notes"></textarea> 
                            <div className="row">
                               <div className="col-lg-6"><input type="button" className="btn_full deliver_here_btn" value="Deliver here" onClick={this.deliver_here}></input></div>
                               <div className="col-lg-6"><input type="button" className="btn_full deliver_here_btn" value="Reset Address" onClick={this.resetAddress}></input></div>
                            </div>                                          
                            
                             
                        </div>
                    </div>
                  
                </div>
              }
                
            </div>
        </>
          );
    }

}

export default GoogleApiWrapper({
  apiKey: MAP_GOOGLE_KEY
})(GoogleMapForLocation);