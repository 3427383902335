import React from 'react';
import Slider from "react-slick";

class CategorySlider extends React.Component {

    constructor(props) {
        super(props);   
        this.categoryFilterClick = this.categoryFilterClick.bind(this); 
    };

    categoryFilterClick = (event) => {        
        event.preventDefault();
        let child_cat_code = event.currentTarget.getAttribute('data-child_cat_code');
        this.props.categoryFunction(child_cat_code);
    }
      
    render(){
        const shops_category = this.props.shops_category;
        const settings = {
            arrows: true,
            dots: false,
            autoplay: false,        
            rows: 1,
            slidesToShow: 13.6,
            slidesToScroll: 13.6,
            infinite: false,         
            initialSlide: 0,
            responsive: [
              
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 7.6,
                  slidesToScroll: 7.6,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 5.6,
                  slidesToScroll: 5.6,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4.6,
                  slidesToScroll: 4.6,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 3.6,
                  slidesToScroll: 3.6
                }
              }
            ]
      };
        return(
            <Slider {...settings}>
              {
                  shops_category ? 
                  shops_category.map((slide, key) => (
                      <div key={key}>
                          <a href='#' onClick={this.categoryFilterClick} data-child_cat_code={slide.child_cat_code}>
                              <img className='cat-img-block' src={slide.image}  alt="" />
                              <div className='cat-name-block'>{slide.name}</div>
                          </a>                    
                      </div>
                  )) : null
              }
            </Slider >
        );
    }
      
}

export default CategorySlider;