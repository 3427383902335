import React, { Component } from 'react';
import axios from "axios";
import { Redirect,  Link   } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import CartSummeryWidegt from '../Widget/cartSummeryWidget';
import ViewCartSkeleton from '../skeleton/view_cart_skeleton';

import 'icheck/skins/all.css';
import {Checkbox} from 'react-icheck';
import "../skeleton/Skeleton.css";

import {PRODUCT_DETAIL_API_URL,ADD_TO_CART_API_URL,REMOVE_CART_ITEM, AWS_IMG_PATH} from '../Comman/Constant';

class productDetail extends Component {
    constructor(props){
        super(props);    

        this.state = {
            productListData: [],     
            shop_data: [], 
            category_data: [],
            add_to_weigh_data: [] ,
            add_to_addon_data: [] ,
            customer_cart_amount: 0,
            isCartLoading: false, 
            device_id : localStorage.getItem('device_id'),
            product_code : localStorage.getItem('productCode'),
            shop_id: this.props.match.params.id
        }

        this.fetchData = this.fetchData.bind(this); 
        this.forceReRander = this.forceReRander.bind(this);
        this.addToCartWeight = this.addToCartWeight.bind(this);    
        this.addToCartAddon = this.addToCartAddon.bind(this);
        this.renderResponse = this.renderResponse.bind(this);
        this.addTocartOverwrite = this.addTocartOverwrite.bind(this);
    }

    componentDidMount(){    
        const requestParam = {
            language_id: 1,
            shop_id: this.state.shop_id,
            device_id: this.state.device_id,
            product_code: this.state.product_code
        }
        this.fetchData(requestParam);
    }

    forceReRander = () =>{
        const requestParam = {
            language_id: 1,
            shop_id: this.state.shop_id,
            device_id: this.state.device_id,
            product_code: this.state.product_code
        }
        this.fetchData(requestParam);
    }

    fetchData = (requestParam) => {
        var that = this;
        axios.post(PRODUCT_DETAIL_API_URL, requestParam,
        { 
            headers: {
            'Authorization': requestParam.device_id 
            }
        })
        .then((response) => {
            this.setState({
                addonInfo: response.data.detail_info.addonInfo,  
                weightInfo: response.data.detail_info.weightInfo,  
                productName: response.data.detail_info.product_name,      
                productImg: response.data.detail_info.image,  
                productDescription: response.data.detail_info.product_description, 
                productWeightLabel: response.data.detail_info.product_weight_label,  
                productAddonLabel: response.data.detail_info.product_addon_label,                                          
            });        
        }).catch(function (error) {               
            if(error.response.status==401){
                that.props.history.push('/sign-in');
            }
        });
    }


  addToCartWeight(event){
      console.log(event.target);
        const target = event.target;
        var value = target.value;
        let product_code = event.currentTarget.getAttribute('data-hfgkkeirn4df');
        
        this.setState({
            isCartLoading: true,                
        });
        var that = this;
        if(!target.checked){
            
            axios.post(ADD_TO_CART_API_URL, {
            
                language_id: 1,
                weight_data: [value],
                shop_id: this.props.match.params.id,
                city_id: this.state.shop_data.city_id,
                device_id: localStorage.getItem('device_id'),
                product_code:  target.dataset.hfgkkeirn4df
            
            },
            { 
                headers: {
                'Authorization': localStorage.getItem('device_id')
                }
            }).then((response) => {     
               
                response.data.weight_data = [value];
                response.data.product_code = product_code;                
                //document.body.click()               
                this.renderResponse(response);
              
            }).catch(function (error) {               
                if(error.response.status==401){
                     that.props.history.push('/sign-in');
                }
            });
        }else{
            axios.post(REMOVE_CART_ITEM, {
            
                language_id: 1,
                cart_table_id: target.dataset.index,
                product_code: target.dataset.hfgkkeirn4df,
                device_id: localStorage.getItem('device_id')
            
            },{ 
                headers: {
                'Authorization': localStorage.getItem('device_id')
                }
            }).then((response) => {
                 this.forceReRander();   
                this.setState({                
                    unique_id: response.data.unique_id, 
                    isCartLoading: false,                              
                  });  
                 
            }).catch(function (error) {               
                if(error.response.status==401){
                     that.props.history.push('/sign-in');
                }
            });
        }
  }

  addTocartOverwrite(response){
    var that = this;
    axios.post(ADD_TO_CART_API_URL, {
            
        language_id: 1,
        weight_data: response.data.weight_data,
        shop_id: this.props.match.params.id,
        city_id: this.state.shop_data.city_id,
        device_id: localStorage.getItem('device_id'),
        overwrite: 'yes',
        product_code:  this.state.product_code,
    
    },
    { 
        headers: {
        'Authorization': localStorage.getItem('device_id') 
        }
    }
    ).then((response) => {                
        this.renderResponse(response);
    }).catch(function (error) {               
        if(error.response.status==401){
                that.props.history.push('/sign-in');
        }
    });
  }

  renderResponse(response){

     response.data.error && document.body.click() 
    
    response.data.error ? 
   
    response.data.overwrite == 'yes' ?  confirmAlert({
        title: '',
        message:response.data.error,
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.addTocartOverwrite(response)                        
          },
          {
            label: 'No',                        
          }
         
        ]
      }) : 
    confirmAlert({
        title: '',
        message:response.data.error,
        buttons: [
          {
            label: 'Okay',                        
          },
         
        ]
      })
      : this.setState({
        customer_cart_amount: response.data.customer_cart_amount,  
        unique_id: response.data.unique_id, 
        isCartLoading: false,   
        error: response.data.error ? response.data.error : ""                              
      });
      this.forceReRander();
      this.setState({
        isCartLoading: false,                
        });

  }

  addToCartAddon(event){
    var that = this;
    const target = event.target;
    var value = target.value;
    let product_code = event.currentTarget.getAttribute('data-hfgkkeirn4df');
    this.setState({
        isCartLoading: true,                
    });
    if(!target.checked){
    
        axios.post(ADD_TO_CART_API_URL, {            
            language_id: 1,
            addon_data: [value],
            shop_id: this.props.match.params.id,
            city_id: this.state.shop_data.city_id,
            device_id: localStorage.getItem('device_id'),
            product_code:  target.dataset.hfgkkeirn4df        
        },
        { 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
        }).then((response) => {
            response.data.weight_data = [value];
            //document.body.click()               
            this.renderResponse(response);   
        }).catch(function (error) {            
            if(error.response.status==401){
                that.props.history.push('/sign-in');
            }
        });
    }else{
        axios.post(REMOVE_CART_ITEM, {        
            language_id: 1,
            cart_table_id: target.dataset.index,
            product_code: target.dataset.hfgkkeirn4df,
            device_id: localStorage.getItem('device_id')        
        },
        { 
            headers: {
            'Authorization': localStorage.getItem('device_id') 
            }
        }).then((response) => {
             this.forceReRander();   
            this.setState({                
                unique_id: response.data.unique_id, 
                isCartLoading: false,                              
            });     
        }).catch(function (error) {               
            if(error.response.status==401){
                    that.props.history.push('/sign-in');
            }
        });;
    }    
}

    render(){
        const device_id = localStorage.getItem('device_id');
        if(!device_id){    
            return <Redirect to='/your_location'/>                 
        }
    
        const addonInfo = this.state.addonInfo;
        const weightInfo = this.state.weightInfo;
        return(
            <div>
               
                <section className="parallax-window" id="cs_short" style={{backgroundImage: `url(${AWS_IMG_PATH + "extra_images/shop_detail_default_banner.png"})`}}>
                    <div id="subheader">
                        <div id="sub_content">
                            <h1>{this.state.productName} </h1>                                              
                            {/* <div><i className="icon_pin"></i>{shopData.location}</div> */}
                        </div>                
                    </div>
                </section>

                <div className="container-fluid full-height mt-3">
                    <div className="row">
                
                        <div className="col-lg-6">
                            <div className="box_style_2" id="main_menu">

                                        <div className="desc" style={{height:"120px"}}>
                                            <div className="thumb_strip">
                                                <img src={AWS_IMG_PATH + "products/thumb/" + this.state.productImg} alt="" />
                                            </div>                                            
                                            <h3 className="">{this.state.productName}</h3>                    
                                            
                                            <div className="type">
                                                {this.state.productDescription}                                                                                    
                                            </div>
                                            <div className="type">
                                                
                                            </div>                                                                          
                                        </div>
                    
                                        {
                                            this.state.isCartLoading == false ? 
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <h4 className="bg-light pt-2 pb-2 pl-2">{this.state.productWeightLabel}</h4>
                                                    {                                                                                
                                                        weightInfo ? 
                                                        weightInfo.map( (weightData,productKey) => {
                                                            return(
                                                                <div className="row" key={productKey}>
                                                                    <div className="col-lg-12">

                                                                        <Checkbox
                                                                        name="weight_data"
                                                                        checkboxClass="icheckbox_square-purple"                                                                                                   
                                                                        value={weightData.id}
                                                                        data-index={weightData.id}
                                                                        data-hfgkkeirn4df={weightData.p_code}
                                                                        onChange={this.addToCartWeight} 
                                                                        checked={weightData.isSelect === '1'}
                                                                        />
                                                                        <label style={{"marginLeft": "10px"}} htmlFor="checkbox1">{weightData.weight_name} <span>+ SAR{weightData.weight_price}</span></label>
                                                                    
                                                                    </div>                                                
                                                                </div>
                                                            ); 
                                                        
                                                        })
                                                        : null                                                                                    
                                                    }       
                                                </div>
                                                <div className="col-lg-6">
                                                    {
                                                         addonInfo && <h4 className="bg-light pt-2 pb-2 pl-2">{this.state.productAddonLabel}</h4>
                                                    }
                                                
                                                    {                                                                                
                                                        addonInfo ? 
                                                        addonInfo.map( (addonData,productKey) => {
                                                            return(
                                                                <div className="row" key={productKey}>
                                                                    <div className="col-lg-12">

                                                                        <Checkbox
                                                                        name="addon_data"
                                                                        checkboxClass="icheckbox_square-purple"                                                                                                   
                                                                        value={addonData.id}
                                                                        data-index={addonData.id}
                                                                        data-hfgkkeirn4df={addonData.pcode}
                                                                        onChange={this.addToCartAddon} 
                                                                        checked={addonData.isSelect === '1'}
                                                                        />
                                                                        <label style={{"marginLeft": "10px"}} htmlFor="checkbox1">{addonData.addon_name} <span>+ SAR{addonData.addon_price}</span></label>
                                                                    
                                                                    </div>                                                
                                                                </div>
                                                            ); 
                                                        
                                                        })
                                                        : null                                                                                    
                                                    }   
                                                </div>
                                            </div>
                                            : 
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <h4 className="bg-light pt-2 pb-2 pl-2">{this.state.productWeightLabel}</h4>
                                                    <div className="row">
                                                        <div className="col-lg-12 skeleton-col-lg-6-short">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h4 className="bg-light pt-2 pb-2 pl-2">{this.state.productAddonLabel}</h4>
                                                    <div className="row">
                                                        <div className="col-lg-12 skeleton-col-lg-6-short">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                 
                            </div>                        
                        </div>
                        <div className="col-lg-6" id="sidebar">
                            {
                                this.state.isCartLoading == false ? 
                                    <CartSummeryWidegt key={this.state.unique_id} location={this.props.location} rerenderParentCallback={this.forceReRander}/>
                                :                             
                                    <ViewCartSkeleton key={this.state.unique_id} location={this.props.location}/>                        
                            }
                                           
                        </div>
                    </div>                
                </div>
        
            </div>
        )
    }
}

export default productDetail;