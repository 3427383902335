import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

import SingleMarkerMap from './SingleMarker'
import { MAP_GOOGLE_KEY } from '../Comman/Constant';

const Map = withScriptjs(
    withGoogleMap(props => {
          
          return(
          <GoogleMap
              defaultZoom={12}
              
              options={{
                zoom: 12,
                center: new window.google.maps.LatLng(parseFloat(localStorage.getItem('customerLat')), parseFloat(localStorage.getItem('customerLng'))),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        
                mapTypeControl: false,
                mapTypeControlOptions: {
                  style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                  position: window.google.maps.ControlPosition.LEFT_CENTER
                },
                panControl: false,
                panControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                zoomControl: true,
                zoomControlOptions: {
                  style: window.google.maps.ZoomControlStyle.LARGE,
                  position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                },
                scrollwheel: false,
                scaleControl: false,
                scaleControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_LEFT
                },
                streetViewControl: true,
                streetViewControlOptions: {
                  position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                },
                styles:
                [{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}]
        
              }}
             
            >

             
              <SingleMarkerMap marks={props.marks}/>
                 

            </GoogleMap>
          )
        
    })
);

class ShopListMap extends Component {
    constructor(props){
      super(props)
    
      this.state = {
          shopData: this.props.shop_data
      }
  }

  
    render() {
    
        const marks = this.state.shopData;
        return (
            <div>              
                <Map
                    googleMapURL= {`https://maps.googleapis.com/maps/api/js?key=${MAP_GOOGLE_KEY}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `800px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}                   
                    marks={marks}
                />;
            </div>
        );
    }
}

export default ShopListMap;